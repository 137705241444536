import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './estate.css'
import img1 from '../images/underdevlopment.webp'
import img2 from '../images/developproperty.webp'
import img3 from '../images/real-estate-development-fund.webp'
import img4 from '../images/indirect-real-estate.webp'
import img5 from '../images/directequity.webp'
import img6 from '../images/privateequity.webp'
import img7 from '../images/Discretionaryimg.webp'
import img8 from '../images/cash.webp'
const Estate = ({selectedLanguage,setBlank}) => {

    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
            setBlank(false);            
        }, 800);
    }

    return (
        <>
            <section className='real-estate-section'  style={(selectedLanguage === "ar") ? {display:"none"} : {}}>
                <Container fluid className='real-estate-bg'>
                    <Row>
                        <Col>
                            <div className='heading-text'>
                                <h4 className='real-estate-heading'>Real estate & infrastructure investments</h4>
                                <p className='real-estate-para text-center'>Our real estate portfolio goes beyond direct and active investment in land, properties and funds to indirect investments through our collaboration with MASIC on its investments in the real estate industry.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='real-estate'>
                    <Row className='real-estate-row1 g-sm-0'>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img1} alt='' />
                                    <h4 className='img-text'>Under Development<br /> Properties</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img2} alt='' />
                                    <h4 className='img-text'>Developed <br /> Properties</h4>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img3} alt='' />
                                    <h4 className='img-text'>Real Estate <br /> Development Funds </h4>
                                </div></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img4} alt='' />
                                    <h4 className='img-text'>Indirect Real <br /> Estate </h4>
                                </div></div>
                        </div>
                    </Row>
                    <Row> 
                        <Col>
                            <NavLink to="/realestate" onClick={scrollToTop}>
                            <button>Read More</button>
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='non-real-estate-bg'>
                    <Row>
                        <Col>
                            <h4 className='non-real-estate-heading'>Non-real estate investments</h4>
                            <p className='non-real-estate-para text-center'>From equity to alternative assets, we carefully curate our portfolio beyond our realm of real estate.</p>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='non-real-estate'>
                    <Row className='non-real-estate-row1 g-sm-0'>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img5} alt='' />
                                    <h4 className='img-text'>Direct Equity</h4>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img6} alt='' />
                                    <h4 className='img-text'>Private Equity</h4>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img7} alt='' />
                                    <h4 className='img-text'>Public Equity</h4>
                                </div></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img8} alt='' />
                                    <h4 className='img-text'>Money Markets and<br /> Fixed Income</h4>
                                </div></div>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <NavLink to="/investments" onClick={scrollToTop}>
                                <button>Read More</button>
                            </NavLink>    
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='real-estate-section ar' style={(selectedLanguage === "en") ? {display:"none"} : {}}>
                <Container fluid className='real-estate-bg'>
                    <Row>
                        <Col>
                            <div className='heading-text'>
                                <h4 className='real-estate-heading'>استثمارات العقارات والبنية التحتية</h4>
                                <p className='real-estate-para text-center'> تشمل محفظة استثماراتنا العقارية استثمارات مباشرة في الأراضي والعقارات والصناديق وتتضمن استثمارات غير مباشرة من خلال تعاوننا مع شركة ماسك في استثماراتها العقارية. </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='real-estate'>
                    <Row className='real-estate-row1 g-sm-0'>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img1} alt='' />
                                    <h4 className='img-text'> المشاريع العقارية قيد الإنشاء </h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img2} alt='' />
                                    <h4 className='img-text'>المشاريع العقارية المكتملة</h4>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img3} alt='' />
                                    <h4 className='img-text'>صناديق التطوير العقاري</h4>
                                </div></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img4} alt='' />
                                    <h4 className='img-text'> العقارات غير المباشرة </h4>
                                </div></div>
                        </div>
                    </Row>
                    <Row> 
                        <Col>
                            <NavLink to="/realestate" onClick={scrollToTop}>
                            <button>اقرأ المزيد</button>
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='non-real-estate-bg'>
                    <Row>
                        <Col>
                            <h4 className='non-real-estate-heading'> الاستثمارات غير العقارية </h4>
                            <p className='non-real-estate-para text-center'> ننوع  أعمالنا  في القطاعات الأخرى غير الاستثمارات العقارية وذلك يتضمن الأسهم والأصول البديلة</p>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='non-real-estate'>
                    <Row className='non-real-estate-row1 g-sm-0'>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img5} alt='' />
                                    <h4 className='img-text'>الأسهم المباشرة</h4>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img6} alt='' />
                                    <h4 className='img-text'>الأسهم الخاصة</h4>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img7} alt='' />
                                    <h4 className='img-text'>الأسهم العامة</h4>
                                </div></div>
                        </div>
                        <div className='col-md-3'>
                            <div className='text-center'>
                                <div className='img-box'>
                                    <img src={img8} alt='' />
                                    <h4 className='img-text'>الدخل الثابت</h4>
                                </div></div>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <NavLink to="/investments" onClick={scrollToTop}>
                                <button> اقرأ المزيد</button>
                            </NavLink>    
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Estate