import React from 'react'
import { Row, Container } from 'react-bootstrap';
import img1 from '../images/corevalueimg.webp'
import img2 from '../images/corevalueimg2.webp'
import img3 from '../images/corevalueimg3.webp'
import img4 from '../images/corevalueimg4.webp'
import leftbottom from '../images/core-value-left.webp'
import righttop from '../images/core-value-right.webp'

import leftbottommb from '../images/core-value-left-mb.webp'
import righttopmb from '../images/core-value-right-mb.webp'
import './corevalue.css'

// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

// AOS.init({
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 400, // values from 0 to 3000, with step 50ms
//     easing: 'ease', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
// });

const Corevalue = ({selectedLanguage}) => {
    const data = [
        {
          "id": 1,
          "image": img1,
          "imgheading": "Integrity",
          "imgcontent": "Our business is built on the pillars of honesty and transparency. These values are at the core of everything we do, and we believe they are essential to our success.",
          animation: "fade-down-right"
        },
        {
          "id": 2,
          "image": img2,
          "imgheading": "Accountability",
          "imgcontent": "We take complete ownership of our actions, decisions and outcomes in all aspects of our operations, ensuring that we consistently exceed expectations.",
          animation: "fade-down"
        },
        {
          "id": 3,
          "image": img3,
          "imgheading": "Respect",
          "imgcontent": "We value everyone and treat people with dignity and professionalism.",
          animation: "fade-down"
        },
        {
          "id": 4,
          "image": img4,
          "imgheading": "Ethical investing",
          "imgcontent": "We are committed to integrating financial goals with responsible and sustainable practices that deliver long term value to our clients and society at large.",
          animation: "fade-down-left"
        }
      ]

      const dataar = [
        {
          "id": 1,
          "image": img1,
          "imgheading": "النزاهة",
          "imgcontent": "قامت أعمالنا على ركيزتي الصدق والشفافية. وتمثل هاتان القيمتان جوهر كل ما نقوم به، ونحن على يقين أنهما ضروريتان لنجاحنا.",
          animation: "fade-down-left"
        },
        {
          "id": 2,
          "image": img2,
          "imgheading": "المساءلة",
          "imgcontent": "نحن مسؤولون تمامًا عن كل ما نقوم به من إجراءات وما نتخذه من قرارات وما نحققه من نتائج في كافة جوانب العمل، لنضمن ديمومة قدرتنا على تحقيق مستوى أعلى من التطلعات.",
          animation: "fade-down"
        },
        {
          "id": 3,
          "image": img3,
          "imgheading": "الاحترام",
          "imgcontent": "نقدر الجميع ونعاملهم بأقصى درجات المهنية والإحترام.",
          animation: "fade-down"
        },
        {
          "id": 4,
          "image": img4,
          "imgheading": "الاستثمار الأخلاقي",
          "imgcontent": "نلتزم بدمج الأهداف المالية مع الممارسات المسؤولة والمستدامة التي تقدم قيمة طويلة الأجل لعملائنا والمجتمع على نطاق أوسع.",
          animation: "fade-down-right"
        }
      ]

    return (
    <>
        <section className={selectedLanguage === "en" ? 'corevalue-section' : 'corevalue-section ar' }>


             <div className='leftbottom desktop' 
              data-aos="fade-right" data-aos-offset="25" data-aos-easing="ease-in-sine"
              data-aos-anchor=".corevalue-section"  data-aos-anchor-placement="top-center">
              <img src={leftbottom} alt='' />
            </div>
                       
              <div className='righttop desktop'
               data-aos="fade-left" data-aos-offset="25" data-aos-easing="ease-in-sine" 
               data-aos-anchor=".corevalue-section" data-aos-anchor-placement="top-center">
                <img src={righttop} alt='' /></div>

            
              <div className='leftbottom mobile'
                data-aos="fade-right" data-aos-offset="25" data-aos-easing="ease-in-sine" 
                data-aos-anchor=".corevalue-section" data-aos-anchor-placement="top-center">
                  <img src={leftbottommb} alt='' />
              </div>
            
              <div className='righttop mobile'
              data-aos="fade-left" data-aos-offset="25" data-aos-easing="ease-in-sine" 
              data-aos-anchor=".corevalue-section" data-aos-anchor-placement="top-center">
                <img src={righttopmb} alt='' />
              </div>

              {selectedLanguage === "en" ? (
                                <div className='mt-5'>
                                <div className='heading-box'>
                                   <h4 className='corevalue-heading'>The Core Principles that Drive MXB</h4>
                                   <p className='corevalue-para desktop'>We value everyone and treat people with dignity and professionalism. It promotes<br/> cooperation and makes it easier for us to achieve our common goals.</p>
                                   <p className='corevalue-para mobile'>We value everyone and treat people with dignity and professionalism. It promotes cooperation and makes it easier for us to achieve our common goals.</p>
                                 </div>
                                   <Row className='mt-lg-5 pt-3 pb-5'>
                                       {/* <div className='col-md-2'></div> */}
                                       <div className='col-md-10 mx-auto'>
                                           <Container>
                                               <Row>
                                                   {
                                                       data.map((d) => {
                                                           return (
                                                                   <div key={d.id} className='col-md-3'>
               
                                                                       <div className='text-center bg-box' data-aos={d.animation} data-aos-offset="25" 
                                                                        data-aos-anchor=".corevalue-section" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-center">
                                                                           <div className='inner-div'>
                                                                               <div className='img-div'>
                                                                               {/* <img style={(d.imgheading === "Respect") ? {paddingTop:"80px"} : ""} src={d.image} alt='' /> */}
                                                                               <img style={(d.imgheading === "Respect") ?  ({paddingTop:"80px"}) : {paddingTop:"50px"}} src={d.image} alt='' />
                                                                               </div>
                                                                               <h4 className='img-heading mt-5 pt-5'>{d.imgheading}</h4>
                                                                               <p className='img-para mt-3 pt-4'>{d.imgcontent}</p>
                                                                           </div>
                                                                       </div>
               
                                                                   </div>
                                                           )
                                                       })
                                                   }
                                               </Row >
                                           </Container>
                                       </div>
                                       {/* <div className='col-md-2'></div> */}
                                   </Row>
               
                               </div>
              ) : (
                <div className='mt-5'>
                <div className='heading-box'>
                   <h4 className='corevalue-heading'>المبادئ الأساسية لشركة مكسب</h4>
                   <p className='corevalue-para desktop'> نقدر الجميع ونعاملهم بأقصى درجات المهنية والإحترام مما يدعم التعاون وييسر لنا تحقيق أهدافنا المشتركة.</p>
                   <p className='corevalue-para mobile'> نقدر الجميع ونعاملهم بأقصى درجات المهنية والإحترام مما يدعم التعاون وييسر لنا تحقيق أهدافنا المشتركة.</p>
                 </div>
                   <Row className='mt-lg-5 pt-3 pb-5'>
                       {/* <div className='col-md-2'></div> */}
                       <div className='col-md-10 mx-auto'>
                           <Container>
                               <Row>
                                   {
                                       dataar.map((d) => {
                                           return (
                                                   <div key={d.id} className='col-md-3'>

                                                       <div className='text-center bg-box' data-aos={d.animation} data-aos-offset="25" 
                                                        data-aos-anchor=".corevalue-section" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-center">
                                                           <div className='inner-div'>
                                                               <div className='img-div'>
                                                               {/* <img style={(d.imgheading === "Respect") ? {paddingTop:"80px"} : ""} src={d.image} alt='' /> */}
                                                               <img style={(d.imgheading === "Respect") ?  ({paddingTop:"80px"}) : {paddingTop:"50px"}} src={d.image} alt='' />
                                                               </div>
                                                               <h4 className='img-heading mt-5 pt-5'>{d.imgheading}</h4>
                                                               <p className='img-para mt-3 pt-4'>{d.imgcontent}</p>
                                                           </div>
                                                       </div>

                                                   </div>
                                           )
                                       })
                                   }
                               </Row >
                           </Container>
                       </div>
                       {/* <div className='col-md-2'></div> */}
                   </Row>

               </div>
              )}


            </section >
    </>
  )
}

export default Corevalue