import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './keyproject.css'

import porject1 from '../images/project1.webp'
import porject2 from '../images/project2.webp'
import porject3 from '../images/project3.webp'
import porject4 from '../images/project4.webp'
import porject5 from '../images/project5.webp'


import infraimg1 from '../images/AlMousaView-img.webp'
import infraimg2 from '../images/JawharatAl-Gharb-img.webp'
import infraimg3 from '../images/Al-Masa-img.webp'
import infralogo1 from '../images/AlMousaView-logo.webp'
import infralogo2 from '../images/JawharatAl-Gharb-logo.webp'
import infralogo3 from '../images/Al-Masa-logo.webp'


import topright from '../images/kp-top-right.webp'
import bottomleft from '../images/kp-bottom-left.webp'

import toprightmb from '../images/kp-top-right-mb.webp'
import bottomleftmb from '../images/kp-bottom-left-mb.webp'

// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

// AOS.init({
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 450, // values from 0 to 3000, with step 50ms
//     easing: 'ease-in', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-top', // defines which position of the element regarding to window should trigger the animation
// });


const Keyproject = ({selectedLanguage}) => {

    const data = [
        { 
            id: 1, 
            img: porject1, 
            heading: "Al Olaya Building", 
            para: 'Located on Musa Ibn Nusair Street and connected to King Fahd road, this property consists of a mix of 58 residential, and 31 commercial units, with the facility of underground parking. It has a net land area of (20,000 sqm) and is well connected to almost the entire city. It is currently leased out.',
            animation: "fade-down-right"
        },
        { 
            id: 2, 
            img: porject2, 
            heading: "Plumeria building", 
            para: 'Located in the Al Yasmin district in northern Riyadh and spread across (13,000 sqm) land area, the property is a three-sided open retail-cum office complex on AL Thumamah Road, available on a lease. The offices and retail stores are directly let-out to the tenants.',
            animation: "fade-down"
        },
        { 
            id: 3, 
            img: porject3, 
            heading: "Al Aqiq Building", 
            para: 'The Al Aqiq Building is a (5,000 sqm) property in the Al Aqiq neighborhood of northern Riyadh. It a multi-story building, and it\'s located in a very lively part of the city. Its convenient proximity to numerous services makes it an ideal location.',
            animation: "fade-down-left"
        }
    ];

    const dataar = [
        { 
            id: 1, 
            img: porject1, 
            heading: "مبنى العليا", 
            para: 'يقع هذا العقار في شارع موسى بن نصير ويتصل بطريق الملك فهد، ويتكون من 58 وحدة سكنية، و31 وحدة تجارية، مع توفير مواقف للسيارات تحت الأرض. تبلغ مساحته الصافية القابلة للتأجير 20,000 مترًا مربعًا وهو في موقع استراتيجي يصله مع المدينة بأكملها تقريبًا وقد تم تأجيره بالكامل.',
            animation: "fade-down-left"
        },
        { 
            id: 2, 
            img: porject2, 
            heading: "مبنى بلوميريا", 
            para: "يقع المبنى في حي الياسمين في شمال الرياض ويمتد على مساحة 13,000 مترًا مربعًا، وهو عبارة عن مجمع مفتوح من ثلاثة جوانب للمتاجر والمكاتب على طريق الثمامة، وهو متاح للإيجار حاليًا ويتم تأجير المكاتب والمتاجر مباشرة للمستأجرين.",
            animation: "fade-down"
        },
        { 
            id: 3, 
            img: porject3, 
            heading: "العقيق ريزيدنس", 
            para: 'تبلغ مساحة أرض مبنى العقيق 5,000 متراً مربعاً، ويقع في منطقة حيوية جداً في حي العقيق شمال الرياض، ويتضمن هذا المبنى طوابق متعددة ويتميز بقربه للعديد من الخدمات، مما يجعل موقعه مثالياً.',
            animation: "fade-down-right"
        }
    ];


    const infraprojects = [
        { 
            id: 1, 
            img: infraimg1, 
            logo: infralogo1, 
            heading: "AlMousa View", 
            gla:"3,000,000 sqm",
            land:"Residential & Commercial Buildings",
            location:"Jeddah",
            para: 'Located on Musa Ibn Nusair Street and connected to King Fahd road, this property consists of a mix of 58 residential, and 31 commercial units, with the facility of underground parking. It has a net land area of (6,300 sqm) and is well connected to almost the entire city. It is currently leased out.',
            animation: "fade-down-right"
        },
        { 
            id: 2, 
            img: infraimg2,
            logo:infralogo2, 
            heading: "Jawharat Al-Gharb", 
            gla:"350,000 sqm",
            land:"Residential villas & Commercial",
            location:"Riyadh",
            para: 'Located in the Al Yasmin district in northern Riyadh and spread across (5,640 sqm) land area, the property is a three-sided open retail-cum office complex on AL Thumamah Road, available on a lease. The offices and retail stores are directly let-out to the tenants.',
            animation: "fade-down"
        },
        { 
            id: 3, 
            img: infraimg3,
            logo:infralogo3, 
            heading: "Al-Masa", 
            gla:"130,858 sqm",
            land:"Residential & Commercial Buildings",
            location:"Tabuk",
            para: 'The Al Aqiq Building is a (1,593 sqm) property in the Al Aqiq neighborhood of northern Riyadh. It a multi-story building, and it\'s located in a very lively part of the city. Its convenient proximity to numerous services makes it an ideal location.',
            animation: "fade-down-left"
        }
    ];


  return (
    <section className={(selectedLanguage === "en") ? "keyproject-section" : "keyproject-section ar"}>

        <div className='topright desktop'
           data-aos="fade-down" data-aos-offset="100" data-aos-easing="ease-in-sine"
           data-aos-anchor=".keyproject-section" 
        >
            <img  src={topright} alt='' />
        </div>

        <div className='topright mobile'
           data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in-sine"
           data-aos-anchor=".keyproject-section" 
        >
            <img className='mobile' src={toprightmb} alt='' />
        </div>


        <div className='bottomleft' 
            data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine" 
            data-aos-anchor=".keyproject-section" 
        >
            <img className='desktop' src={bottomleft} alt='' />
            <img className='mobile' src={bottomleftmb} alt='' />
        </div>
    
    <Container>
        <Row>
            <div className='text-box text-center'>
                <Container>

                {(selectedLanguage === "en") ? (
                    <>
                        <Row className='header'>
                            <h2>Key Projects</h2>
                            <p>Developed properties</p>
                        </Row>
                        <Row className='justify-content-center mt-lg-5'>

                        {data.map((d, i) => (
                        <div key={d.id} className='col-md-4'>
                            <div className='img-box'
                            data-aos={d.animation} data-aos-offset="100" data-aos-easing="ease-in-sine"
                            data-aos-anchor=".keyproject-section" 
                            data-aos-anchor-placement='top-center'
                            >
                                <div className=''>
                                        <img src={d.img} alt='' />
                                    </div>
                                    <div>
                                        <h2>{d.heading}</h2>
                                        <p>{d.para}</p>
                                    </div>
                            </div>
                        </div>
                        
                        ))}

                        </Row>
                    
                    </>
                ) : (
                    <>
                        <Row className='header'>
                            <h2> المشاريع الرئيسية</h2>
                            <p>المشاريع العقارية المكتملة</p>
                        </Row>
                        <Row className='justify-content-center mt-lg-5'>

                        {dataar.map((d, i) => (
                        <div key={d.id} className='col-md-4'>
                            <div className='img-box'
                            data-aos={d.animation} data-aos-offset="100" data-aos-easing="ease-in-sine"
                            data-aos-anchor=".keyproject-section" 
                            data-aos-anchor-placement='top-center'
                            >
                                <div className=''>
                                        <img src={d.img} alt='' />
                                    </div>
                                    <div>
                                        <h2>{d.heading}</h2>
                                        <p>{d.para}</p>
                                    </div>
                            </div>
                        </div>
                        
                        ))}

                        </Row>
                    </>
                )}
                    


                    
                    <Row className='mb-5 row g-5 mt-1'>    
                        <div className='col-md-3 mt-4 undev-left'>
                        {(selectedLanguage === "en") ? (
                        <>
                            <h2 className='mobile'>Under-development properties</h2>
                            <h2 className='desktop'>Under-development<br/> properties</h2>
                        </>) : (<>
                            <h2>عقارات قيد التطوير</h2>
                        </>) }
                        </div>
                        
                        <div className='col-md-9 mt-4'>
                            <div className='g-5 d-flex prow-2'>
                                <div className='pro1 undev-right'
                                 data-aos="fade-up" data-aos-offset="100" data-aos-easing="ease-in-sine"
                                 data-aos-anchor=".keyproject-section" 
                                 data-aos-anchor-placement='top-center'
                                 >
                                    <Row className=''>
                                        <div className='col-md-4 col-xs-4 p-0'>
                                            <img src={porject4} alt='' />
                                        </div>
                                        <div className='col-md-8 col-xs-8 mx-auto'>
                                            <div className='text-box'>
                                                <h2 className='text-left'> {(selectedLanguage === "en") ? "The Axis " : "أكسيس بلازا" }</h2>
                                                <p className='mt-2'> {(selectedLanguage === "en") ? "A demolished & re-developed multi-use retail and office property, located on Olaya Street, directly opposite King Abdullah Financial District, this property is spread over a buildup area of (55,000 sqm) and is well connected with King Fahd Road, Al Thumama Road, and the upcoming metro lines." :
                                                 "مبنى متعدد الاستخدامات يضم متاجر ومكاتب إدارية فئة أ،  في شارع العليا مقابل مركز الملك عبد الله المالي . يمتد هذا العقار على مساحة 9,000 متراً مربعاً  - مسطحات بناء 55,000 متر مربع ويتميز لوقوعه في منطقة مركزية تتيح الوصول لخمسة طرق رئيسية بالاضافة الى محطة المترو للمركز المالي. " }</p>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className='pro2 undev-right'
                                    data-aos="fade-up" data-aos-offset="100" data-aos-easing="ease-in-sine"
                                    data-aos-anchor=".keyproject-section" data-aos-anchor-placement='top-center'
                                >
                                    <Row className=''>
                                        <div className='col-md-4 col-xs-4 p-0'>
                                            <img src={porject5} alt='' />
                                        </div>
                                        <div className='col-md-8 col-xs-8 mx-auto'>
                                            <div className='text-box'>
                                                <h2 className='text-left'> {(selectedLanguage === "en") ? "Al qirawan Residences" : "مساكن القيروان" }</h2>
                                                <p className='mt-2'> {(selectedLanguage === "en") ? "Al Qirawan Residences, with a total land area of (3,000 sqm) and a prime location on King Salman Bin Abdulaziz Road, are situated in the newest part of the city. In the near future, MXB intends to develop this property into a residential complex." 
                                                : "تقع مساكن القيروان التي تبلغ مساحتها الإجمالية 3,000 مترًا مربعًا في بقعة متميزة على طريق الملك سلمان بن عبد العزيز، ومجمع الأفنيوز في الجانب الجديد من المدينة وتعتزم شركة مكسب تطوير هذا العقار إلى مجمع سكني في المستقبل القريب."}</p>
                                                <br />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Row>


                        {/* Key Infrastructure Projects */}

                        {(selectedLanguage === "en") ? (
                    <>
                        <Row className='header mt-5 pt-5'>
                            <p>Key Infrastructure Projects</p>
                        </Row>
                        <Row className='justify-content-center infra-prjects mt-lg-5v mb-5'>

                        {infraprojects.map((d, i) => (
                        <div key={d.id} className='col-md-4'>
                            <div className='img-box'
                            data-aos={d.animation} data-aos-offset="100" data-aos-easing="ease-in-sine"
                            data-aos-anchor=".keyproject-section" 
                            data-aos-anchor-placement='top-center'
                            >
                                <div className='img-box'>
                                    <img src={d.img} alt='' />
                                        <div className='logo-box'>
                                            <img src={d.logo} alt=''/>        
                                        </div>    
                                </div>
                                <div className='text-box'>
                                    <h2>{d.heading}</h2>
                                    <div className='grid-2'>
                                        <div>GLA </div>
                                        <div><span>{d.gla}</span></div>
                                        <div>Land Use </div>
                                        <div><span>{d.land}</span></div>
                                        <div>Location:</div>
                                        <div><span>{d.location}</span></div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        ))}

                        </Row>
                    
                    </>
                ) : (
                    <>
                        <Row className='header mt-5 pt-5'>
                            <p>Key Infrastructure Projects</p>
                        </Row>
                        <Row className='justify-content-center infra-prjects mt-lg-5v mb-5'>

                        {infraprojects.map((d, i) => (
                        <div key={d.id} className='col-md-4'>
                            <div className='img-box'
                            data-aos={d.animation} data-aos-offset="100" data-aos-easing="ease-in-sine"
                            data-aos-anchor=".keyproject-section" 
                            data-aos-anchor-placement='top-center'
                            >
                                <div className='img-box'>
                                    <img src={d.img} alt='' />
                                        <div className='logo-box'>
                                            <img src={d.logo} alt=''/>        
                                        </div>    
                                </div>
                                <div className='text-box'>
                                    <h2>{d.heading}</h2>
                                    <div className='grid-2'>
                                        <div>GLA </div>
                                        <div><span>{d.gla}</span></div>
                                        <div>Land Use </div>
                                        <div><span>{d.land}</span></div>
                                        <div>Location:</div>
                                        <div><span>{d.location}</span></div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        ))}

                        </Row>
                    
                    </>
                )}
                    


                </Container>
            </div>
        </Row>
    </Container>
    </section>
  )
}

export default Keyproject
