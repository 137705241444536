import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import './mxbinvestment.css'
import img1 from '../images/Visionlogo.webp'
import visionmb from '../images/visionmb.webp'

import img2 from '../images/msgleft-img.webp'
import img3 from '../images/msgright-img.webp'
import { NavLink } from 'react-router-dom';

import topright from '../images/investment-top.webp'
import bottomleft from '../images/investment-bottom.webp'


import toprightmb from '../images/investment-top-mb.webp'
import bottomleftmb from '../images/investment-bottom-mb.webp'

// import topright from '../images/investment-top-mb.webp'
// import bottomleft from '../images/investment-bottom-mb.webp'

const Mxbinvestment = ({selectedLanguage,setBlank}) => {
    // const paragraph = "MXB Investment, established in 1984 in Riyadh, KSA, is a Shariah-compliant Family-Owned Investment company with investments spread across multiple asset classes such as real estate, public and private equities, structured investments & DPM. We firmly believe in the power of ethical investing, guided by Islamic principles."
  
    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
            setBlank(false);            
        }, 700);
    }

    return (
        <>
            <div className='investment-bg'>
                {/* investmentsection starts here */}
                <section className='investment-section'>
                
                    <div className='topright'>
                        <div data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" 
                          data-aos-anchor=".investment-section" 
                        data-aos-anchor-placement='top-center'
                        >
                            <img className='desktop' src={topright} alt='' />
                            <img className='mobile' src={toprightmb} alt='' />
                        </div>
                    </div>
                    
                    
                    <div className='bottomleft'>
                        <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" 
                        //  data-aos-anchor-placement='top-bottom' 
                         data-aos-anchor=".investment-section" >
                            <img className='desktop' src={bottomleft} alt='' />
                            <img className='mobile' src={bottomleftmb} alt='' />
                        </div>    
                    </div>
                      
                    <Container>

                        <Row>

                            <div className='img-text text-center' style={selectedLanguage === "ar" ? {direction: "rtl"} : {} }>
                                {/* <div className='topright'><img src={topright} alt='' /></div>
                                <div className='bottomleft'><img src={bottomleft} alt='' /></div> */}
                                <Container>
                                    <Row>
                                            {selectedLanguage === "en" ? (
                                              <>
                                                <Col className='col-md-9'>
                                                 <h2 className='img-heading'>We are <br /> MXB Investment</h2>
                                                </Col>
                                              </>
                                            ) : (
                                                <>
                                                <Col className='col-md-6'>
                                                    <h2 className={selectedLanguage === "en" ? 'img-heading' : 'img-heading ar' }>نبذة عامة عن الشركة</h2>
                                                </Col>    
                                                </>
                                            )}
                                    </Row>
                                    <Row>
                                        <Col className='col-md-4 desktop'></Col>
                                        <Col className='col-md-8 w-sm-100'>
                                           
                                            {selectedLanguage === "en" ? (
                                                <>
                                                    <p className='img-para'> MXB Investment, established in 1984 in Riyadh, KSA, is a Shariah-compliant Family-Owned Investment company with investments spread across multiple asset classes such as real estate, public equities, and private equity. We firmly believe in the power of ethical investing, guided by shariah principles.</p>
                                                    <NavLink to="/about" onClick={scrollToTop}><button className='mt-5'>Learn More</button></NavLink>
                                                </>
                                            ) : (
                                                <>
                                                    <p className='img-para'> شركة استثمارية  تتبع أحكام الشريعة الإسلامية تأسست في الرياض عام 1984 تستهدف الاستثمار في فئات أصول متعددة تشمل على العقارات والأسهم العامة والخاصة والاستثمارات البديلة. نؤمن في شركة مكسب للاستثمار بقوة الاستثمار الأخلاقي وتطبيق مبادئ الشريعة الإسلامية.</p>
                                                    <NavLink to="/about" onClick={scrollToTop}><button className='mt-5'> اقرأ المزيد</button></NavLink>
                                                </>
                                            )}
                                            </Col>

                                    </Row>
                                </Container>
                            </div>
                        </Row>
                    </Container>
                </section>

                <section className={(selectedLanguage === "en") ? "embracing-section" : "embracing-section ar"} >
                    <Container>
                        <Row className='g-0 mx-auto'  style= {selectedLanguage === "ar" ? {direction: "rtl"} : {} }>
                            <div className='col-md-6 paddingleft'>
                               
                               
                            {selectedLanguage === "en" ? (
                                <>
                                    <h2>Embracing <span className='bottom-border'>Saudi Vision 2030 </span>
                                        <span className='boldheading'> Dedicated to growth and prosperity</span> </h2>

                                    {/* <div className='mt-4'><p className='embrac-para1'>We have been actively participating in furthering the Saudi Vision 2030 that strives to transform</p></div> */}

                                    <Row className='paragraph-div'>

                                        <div className='mobile'>  <img src={visionmb} alt='' className='vision-logo' /></div>

                                        <p className='embrac-para2'>MXB is committed to aligning itself with the Saudi Vision 2030, contributing to the real estate and investments sector as part of a collaborative effort to meet the ambitious objectives outlined by this dynamic and visionary plan. This commitment stems from the company's long family history of contributing to the Saudi economy, which dates back three generations. Through our contributions to the development of the Kingdom of Saudi Arabia, which is being guided by the wise leadership of His Majesty King Salman bin Abdulaziz Al-Saud, Custodian of the Two Holy Mosques, and His Royal Highness Prince Muhammad bin Salman bin Abdulaziz, we are laying foundations for a more prosperous future for everyone through our relentless effort in building Saudi Arabia’s real estate, construction and investment industries.</p>

                                    </Row>
                                
                                </>
                                ) : (
                                <>
                                {/* الإيمان برؤية السعودية 2030:نعمل من أجل النمو والازدهار
                                 نسير على خطى رؤية السعودية 2030 لتحقيق مستهدفات النمو والازدهار */}
                                    <h2>نسير على خطى رؤية <span className='bottom-border'> السعودية 2030 </span>
                                        <span className='boldheading'>لتحقيق مستهدفات النمو والازدهار</span> </h2>

                                    {/* <div className='mt-4'><p className='embrac-para1'>We have been actively participating in furthering the Saudi Vision 2030 that strives to transform</p></div> */}

                                    <Row className='paragraph-div'>

                                        <div className='mobile'>  <img src={visionmb} alt='' className='vision-logo' /></div>

                                        <p className='embrac-para2'> تلتزم شركة مكسب للاستثمار بمطابقة توجهاتها مع ركائز رؤية السعودية 2030، حيث تركز على قطاع العقارات والاستثمارات كجزء من جهودها للمشاركة في تحقيق الأهداف الطموحة التي حددتها هذه الرؤية. وينبع هذا الالتزام من التاريخ العائلي الطويل الممتد على مدار ثلاثة أجيال والذي بذلته الشركة في المساهمة في الاقتصاد السعودي.  في ظل قيادة خادم الحرمين الشريفين الملك سلمان بن عبد العزيز آل سعود، وصاحب السمو الملكي ولي العهد الأمير محمد بن سلمان بن عبد العزيز آل سعود - حفظهما الله -، نضع الأسس لمستقبل أكثر ازدهارًا للجميع، ويتمثل ذلك في بذل جهودنا الدؤوبة في تأسيس استثمارات واعدة في قطاع  العقارات والإنشاءات في المملكة العربية السعودية.</p>

                                    </Row>

                                </>        
                                )}

                            </div>
                            <div className='col-md-6 reltv-potn desktop'>
                                <div className='d-flex' style={{justifyContent:"center"}}>
                                 <img src={img1} alt='' className='vision-logo' />
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
                <section className='message-section' style= {selectedLanguage === "ar" ? {direction: "rtl"} : {} }>
                    <Container className='paddingtop'>

                    {selectedLanguage === "ar" ? (
                  <>
                  {/* <div className='my-5 py-5'> */}
                     <Row className='relative'>
                        <div className='leftmsgimg'>
                            <img src={img2} alt=''/>
                        </div>

                            <h4 className='msg-heading'>رسالة رئيس مجلس الإدارة </h4>                           
                            <div className=''>
                                <p className='msg-para text-center'> نستهدف خلق قيمة استثنائية على المدى الطويل لمساهمينا من خلال الاستثمار في أصول متنوعة والالتزام بمبادئ وأحكام الشريعة الإسلامية، نحن على يقين بأن نجاحنا لا يرتبط فقط بالمكاسب المالية، بل يمتد إلى الأثر الإيجابي الذي نتركه في المجتمعات التي نستثمر بها، كما أننا نلتزم في شركة مكسب للاستثمار بخلق مستقبل أفضل لجميع الأطراف والمساهمة في نمو الأفراد والمجتمعات والوطن.
                                    </p>
                                
                                <p className='mt-5 sub-para'>السيدة ندى محمد السبيعي<br />
                                رئيس مجلس إدارة شركة مكسب للاستثمار</p>
                            </div>

                            <div className='rightmsgimg mobile'>
                                <img src={img3} alt=''/>
                            </div>

                        </Row>
 
                        {/* <Row className='justify-content-center  mt-5 pt-5'> */}
                        <Row className='justify-content-center'>
                        <NavLink to="/about" onClick={scrollToTop}>
                           <button className='msg-btn px-2'> اقرأ المزيد</button>
                        </NavLink>
                        </Row>
                                
                        {/* </div> */}
                        </>
                        ) : (
                        <>
                        <Row className='relative'>
                        <div className='leftmsgimg'>
                            <img src={img2} alt=''/>
                        </div>

                            <h4 className='msg-heading'>Message from the Chairman</h4>                           
                            <div className=''>
                                <p className='msg-para text-justify'>Our goal is to create long-term value for our shareholders by making sustainable investments in diverse asset classes while adhering to Islamic principles. We believe that our success is not only measured by financial gains but also by the positive impact we make on the communities we operate in. At MXB, we are committed to creating a better future for all stakeholders and contributing to the growth and development of our people, communities, and nation.
                                    </p>

                                <p className='mt-5 sub-para'>Mrs. Nada Mohammed Alsubeaei<br />
                                Chairman, MXB Investment Company</p>
                            </div>

                            <div className='rightmsgimg mobile'>
                                <img src={img3} alt=''/>
                            </div>

                        </Row>
 
                        <Row className='justify-content-center'>
                        <NavLink to="/about" onClick={scrollToTop}>
                           <button className='msg-btn'>Read More</button>
                        </NavLink>
                        </Row>
          
                        </>        
                        )}

                        {/* <Row className='relative'>
                        <div className='leftmsgimg'>
                            <img src={img2} alt=''/>
                        </div>

                            <h4 className='msg-heading'>Message from the Chairman</h4>                           
                            <div className=''>
                                <p className='msg-para text-justify'>Our goal is to create long-term value for our shareholders by making sustainable investments in diverse asset classes while adhering to Islamic principles. We believe that our success is not only measured by financial gains but also by the positive impact we make on the communities we operate in. At MXB, we are committed to creating a better future for all stakeholders and contributing to the growth and development of our people, communities, and nation.
                                    </p>

                                <p className='mt-5 sub-para'>Mrs. Nada Mohammed Alsubeaei<br />
                                Chairman, MXB Investment Company</p>
                            </div>

                            <div className='rightmsgimg mobile'>
                                <img src={img3} alt=''/>
                            </div>

                        </Row>
 
                        <Row className='justify-content-center'>
                        <NavLink to="/about" onClick={scrollToTop}>
                           <button className='msg-btn'>Read More</button>
                        </NavLink>
                        </Row> */}
 
 
                        <div className='rightmsgimg desktop'>
                                <img src={img3} alt=''/>
                            </div>

                    </Container>
                </section>
            </div>
        </>
    )
}

export default Mxbinvestment