import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import './exploreinvestment.css'
import img1 from '../images/exploreimg.webp'
import img1mb from '../images/exploreimg-mb.webp'

import topleft from '../images/explore-top.webp'
import bottomright from '../images/explore-bottom.webp'

import topleftmb from '../images/explore-top-mb.webp'
import bottomrightmb from '../images/explore-bottom-mb.webp'

const ExploreInvestment = ({setBlank,selectedLanguage}) => {


    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
            setBlank(false);            
        }, 900);
    }
    
    return (
        <>

        <section className={(selectedLanguage === "en") ? "explore-section" : "explore-section ar"}>
                
                <div className='bottomright'
                data-aos="fade-up" data-aos-offset="100" data-aos-easing="ease-in-sine"  
                data-aos-anchor=".explore-section" data-aos-anchor-placement="top-center"  
                >
                    <img className='desktop' src={bottomright} alt='' />
                    <img className='mobile' src={bottomrightmb} alt='' />
                </div>
                <div className='topleft'
                data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine"
                data-aos-anchor=".explore-section" data-aos-anchor-placement="top-center" 
                >
                    <img className='desktop' src={topleft} alt='' />
                    <img className='mobile' src={topleftmb} alt='' />
                </div>

   
                <Container className='relative desktop'>
                    <Row>
                        <Col className=''>
                            <img src={img1} alt='' className='explore-image' />
                        </Col>
                    </Row>

                    <Row className='explore-container'>
                    {(selectedLanguage === "en") ? 
                    <>
                      <Col className='col-md-7 text-start'>
                            <h1 className='explore-heading'>
                                {/* Explore investment opportunities with <br/><span className='fw-bold'>MXB</span> */}
                                Get In Touch
                            </h1>
                            <NavLink to="/contact" onClick={scrollToTop}>
                              <button className='contact-btn'>Contact Us</button>
                            </NavLink>  
                        </Col>
                    </> :
                    <>
                      <Col className='col-md-6 text-end'>
                            <h1 className='explore-heading'>تعرّف على فرص الاستثمار مع مكسب  للاستثمار</h1>
                            <NavLink to="/contact" onClick={scrollToTop}>
                              <button className='contact-btn'>تواصل معنا</button>
                            </NavLink>  
                        </Col>
                    </> 
                    
                    }
                      
                    </Row>
                </Container>

                <Container fluid className='relative mobile'>
                    <Row>
                        <Col>
                        {(selectedLanguage === "en") ? 
                        <>
                           <div className='img-text'>
                                <h1 className='explore-heading'>
                                    {/* Explore investment opportunities with <br/><span className='fw-bold'>MXB</span>  */}
                                    Get In Touch
                                </h1>
                                <img src={img1mb} alt='' className='explore-image' />
                                <NavLink to="/contact" onClick={scrollToTop}>
                                  <button className='contact-btn'>Contact Us</button>
                                </NavLink> 
                            </div>

                        </> : <>
                         <div className='img-text'>
                                <h1 className='explore-heading'>تعرّف على فرص الاستثمار مع مكسب </h1>
                                <img src={img1mb} alt='' className='explore-image' />
                                <NavLink to="/contact" onClick={scrollToTop}>
                                  <button className='contact-btn'>تواصل معنا</button>
                                </NavLink> 
                            </div>
                        </>
                        
                        }
                            
                        </Col>
                    </Row>
                </Container>

            </section>
          
        </>
    )
}

export default ExploreInvestment