import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './termsheader.css'

const TermsHeader = ({selectedLanguage}) => {
  return (
    <section>
    <Container fluid className='termsheader-section'>
        <Row>
        <h1 className='realestate-heading'>Terms and conditions</h1>
            {/* {selectedLanguage === "en" ? (
              <h1 className='realestate-heading'>Terms and conditions</h1>
            ) : (
              <h1 className='realestate-heading'>الشروط والأحكام</h1>
              )} */}
        </Row>
    </Container>
</section>

  )
}

export default TermsHeader
