import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import TermsHeader from '../component/TermsHeader'
import TermsAndConditonComp from '../component/TermsAndConditonComp'
import Footer from '../component/Footer';

const Terms = (props) => {
  return (
    <>
    <Helmet>
      <title>Terms & Conditions</title>
      <meta name='description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
      <Menu setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage}  />
      <TermsHeader selectedLanguage={props.selectedLanguage} />
      <TermsAndConditonComp selectedLanguage={props.selectedLanguage} />
      <Footer setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} />
    </>
  )
}

export default Terms
