import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import Aboutheader from '../component/Aboutheader'
import Corevalue from '../component/Corevalue'
import AboutDesk from '../component/AboutDesk'
import AboutManagingDirector from '../component/AboutManagingDirector'
import Footer from '../component/Footer';
// import AboutJourney from '../component/AboutJourney'
import Journey from '../component/Journey'

const About = (props) => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name='description' content=''/>
        <meta name='keywords' content=''/>
      </Helmet>
    <Menu setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage} />
    <Aboutheader selectedLanguage={props.selectedLanguage}/>
    <AboutDesk selectedLanguage={props.selectedLanguage} />
    {/* <AboutJourney/> */}
    <Journey selectedLanguage={props.selectedLanguage} />
    <Corevalue selectedLanguage={props.selectedLanguage} />
    <AboutManagingDirector selectedLanguage={props.selectedLanguage} />
    <Footer setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} />
    </>
  )
}

export default About  