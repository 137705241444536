import React, { useState } from 'react'
import './careercontactform.css'
import { Row, Container, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Box from '@mui/material/Box';
import { TextareaAutosize } from '@mui/material';

import 'react-phone-number-input/style.css'
// import PhoneInput, { formatPhoneNumber, isPossiblePhoneNumber }  from 'react-phone-number-input'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber }  from 'react-phone-number-input'

import { useFormik } from 'formik';
import * as Yup from 'yup';
import topleft from '../images/career-contact-top.webp'
import bottomright from '../images/career-contact-bottom.webp'

import topleftmb from '../images/career-contact-top-mb.webp'
import bottomrightmb from '../images/career-contact-bottom-mb.webp'

let countries = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }  
];

const initialValues = {
    name: "",
    jobrole: "",
    nationality: "",
    // country: "",
    email: "",
    // phone: "",
    // message: "",
    resume: null 
}

// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png","image/webp", ".doc", ".docx", "application/pdf"];
const careerformVlidation = Yup.object().shape({
    name: Yup.string().min(2).max(25).required("Please enter your Name"),
    jobrole: Yup.string().min(4).required("Please enter Job Role"),
    // country: Yup.string().required("Please select your Country"),
    nationality: Yup.string().required("Please select your Nationality"),
    email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
  // phone: Yup.string().min(10).max(10).required("Please enter your Phone Number"),
    // message: Yup.string().min(4).required("Please enter Message"),
    resume: Yup.mixed().required("Please select resume").test('fileSize', "File is too large", value => value.size <= 2621440).test("is-valid-type", "Please Select Only doc, docx and pdf file", value => isValidFileType(value && value.name.toLowerCase(), "image"))
    // .test('fileType', "Your Error Message", value => SUPPORTED_FORMATS.includes(value.type) )

  });

  const validFileExtensions = { image: ['doc', 'docx', 'pdf'] };
  function isValidFileType(fileName, fileType) {
   return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
  }

const CareerContactForm = ({selectedLanguage}) => {

    const [hide, setHide] = useState(false);
    const [modalShow, setModalShow] = useState();
    const [phone, setphone] = useState();
    const [phoneerrmsg, setphoneerrmsg] = useState();

    const { values, errors, touched, handleBlur, handleChange, handleSubmit,setFieldValue } =
        useFormik({
            initialValues,
            validationSchema: careerformVlidation,
            onSubmit: (values, action) => {


                if(formatPhoneNumber(phone) === "") {
                    setphoneerrmsg("Please enter your Phone Number")
                } else {

                    setphoneerrmsg();
                    console.log(values);


                    setphoneerrmsg();
                    console.log(values);

                    const formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("jobrole", values.jobrole);
                    formData.append("nationality", values.nationality);
                    formData.append("email", values.email);
                    formData.append("phone",  formatPhoneNumberIntl(phone));
                    formData.append("message", values.message);
                    formData.append("resume", values.resume);
                 
                    setHide(true);
                    // https://blog.mxb.sa/wp-json/careers/mail
                    // https://blog.oneweb.agency/wp-json/careers/mail
                    fetch('https://blog.mxb.sa/wp-json/careers/mail', {
                        method: 'POST',
                        // mode: 'no-cors',
                        // headers: {
                        //     "accept":"*",
                        //     "Content-Type": "multipart/form-data",
                        // },
                       
                        body:formData,
                        })
                        //.then(res => res.json())
                        .then(data => {
                             // enter you logic when the fetch is successful
                            console.log(data)
                            
                            setHide(false);
                            setModalShow(true)
                            
                        //   setresonseMsg(data.message);


                        })
                      
                        // .catch(error => {
                        //     // enter your logic for when there is an error (ex. error toast)
                        //     console.log(error);
                        // }) 
                      
                        const fileInput = document.getElementById('file-input');
                        if (fileInput) {
                          fileInput.value = '';
                        }
                        setphone("");
                        action.resetForm();
                   
                }
            },
        });
 
    return (
        <>
            <section className={(selectedLanguage === "en") ? 'career-contact-section pb-5' : 'career-contact-section ar pb-5' }>
                  <div className='topleft'
                   data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine"
                   data-aos-anchor-placement='top-center'
                   >
                    <img className='desktop' src={topleft} alt='' />
                    <img className='mobile' src={topleftmb} alt='' />
                  </div>
                  
                  <div className='bottomright'
                    data-aos="fade-up" data-aos-offset="0" data-aos-easing="ease-in-sine"
                    data-aos-anchor-placement='top-bottom'
                    >
                    <img className='desktop' src={bottomright} alt='' />
                    <img className='mobile' src={bottomrightmb} alt='' />
                  </div>
                <div>
 
                    <h4 className='career-contact-heading pt-5'>{(selectedLanguage === "en") ? 'Apply for a role at MXB' : 'انضم إلى فريقنا الآن' }</h4>
                </div>
                <Container>
                    <Row>
                        <Col className='col-md-4 mx-auto'>
                      
                                        <Box component="form" onSubmit={handleSubmit} sx={{
                                                    '& .MuiTextField-root': {  width: '100%' },
                                                }} className='padding-right' encType="multipart/form-data">
                                             <div className='mt-4'>
                                               <TextField id="name" label={(selectedLanguage === "en") ? "Name*" : "الاسم*" } variant="outlined" value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                               <div className='errormsg'> {errors.name && touched.name ? ( <p className="form-error">{errors.name}</p> ) : null}</div>
                                             </div>
                                             <div className='mt-4'>
                                               <TextField id="jobrole" label={(selectedLanguage === "en") ? "Job Role*" : "المسمى الوظيفي*" } variant="outlined" value={values.jobrole} onChange={handleChange} onBlur={handleBlur} name="jobrole" />
                                               <div className='errormsg'> {errors.jobrole && touched.jobrole ? ( <p className="form-error">{errors.jobrole}</p> ) : null}</div>
                                             </div>
                                             <div className='mt-4 text-left'>
                                             <FormControl className='w-100' >
                                                    <InputLabel id="Nationality">{(selectedLanguage === "en") ? "Nationality*" : "الجنسية*" }</InputLabel>
                                                    <Select
                                                        labelId="Nationality"
                                                        value={values.nationality}
                                                        // value={nationality}
                                                        label="Nationality"
                                                        name="nationality"
                                                        // variant="outlined"
                                                        onChange={handleChange}
                                                        className='w-100'
                                                    >

                                                    {countries.map((country) => {
                                                        return <MenuItem key={country.code} value={country.name}>{country.name} ({country.code})</MenuItem>
                                                        // <option key={country.code} value={country.name}>{country.name} ({country.code})</option>
                                                    })}    
                                                        
                                                        {/* <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem> */}
                                                    </Select>
                                                    </FormControl>
                                                <div className='errormsg'> {errors.nationality && touched.nationality ? ( <p className="form-error">{errors.nationality}</p> ) : null}</div>
                                             </div>
                                             <div className='mt-4'>
                                               <TextField id="email" type='email' label={(selectedLanguage === "en") ? "Email Address" : "البريد الإلكتروني*" } variant="outlined" value={values.email} onChange={handleChange} onBlur={handleBlur} name='email' />
                                               <div className='errormsg'> {errors.email && touched.email ? ( <p className="form-error">{errors.email}</p> ) : null}</div>
                                             </div>
                                             <div className='mt-4'>
                                               {/* <TextField id="phone" label="phone" variant="outlined" value={values.phone} onChange={handleChange} onBlur={handleBlur} name="phone" /> */}
                                               <PhoneInput placeholder={(selectedLanguage === "en") ? "Phone" : "رقم التواصل*" } name="phone"  aria-labelledby="phone" value={phone}                                                         country="US"
                                                       international
                                                       countryCallingCodeEditable={false}
                                                       defaultCountry="SA"
                                                       onChange={setphone}
                                                        // onBlur={handleBlur} 
                                                        rules={{ required: true, validate: isPossiblePhoneNumber }}
                                                        /> 

                                                    <div className='errormsg'> {phoneerrmsg ? (
                                                        <p className="form-error">{phoneerrmsg}</p>
                                                    ) : null}</div>
                                             </div>
                                             
                                             <div className='mt-4'>
                                               <TextareaAutosize id="message" label={(selectedLanguage === "en") ? "Your Message" : "الرسالة" }  variant="outlined" className='w-100 msgfield' placeholder={(selectedLanguage === "en") ? "Your Message" : " الرسالة " } minRows={5} value={values.message} onChange={handleChange} onBlur={handleBlur} name="message" />
                                               <div className='errormsg'> {errors.message && touched.message ? ( <p className="form-error">{errors.message}</p> ) : null}</div>
                                             </div>

                                             <div className='mt-3'>
                                                <TextField id='file-input' type='file' name="resume"
                                                // onChange={(event) => {setFieldValue("file", event.currentTarget.files[0]);}} 
                                                accept=".doc, .docx,.pdf" 
                                                onChange={(event) => {
                                                  const files = event.target.files[0];
                                                  setFieldValue("resume", files);
                                                }}
                                               variant="outlined" className='w-100 fileinput' />
                                               <div className='errormsg'> {errors.resume && touched.resume ? ( <p className="form-error">{errors.resume}</p> ) : null}</div>
                                            </div>

                                            <div className='mt-3'>
                                                {(hide) ? (
                                                    <button 
                                                        className='submit-btn'
                                                        disabled
                                                        style={{opacity:"0.5"}}
                                                        type="submit"
                                                    >
                                                        Apply
                                                    </button>
                                                    
                                                    ) :  (
                                                        <button className='submit-btn' type='submit'>Apply</button>
                                                    )
                                                    }
                                            </div>
                                           </Box>                                
     
                        </Col>
                    </Row>
                </Container>


                {/* success popup */}
                <Modal
                     show={modalShow}
                     size="lg"
                     aria-labelledby="contained-modal-title-vcenter"
                     centered
                    >
                    {/* <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                        </Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body className='text-center p-5'>
                        <h4>Success!</h4>
                        <p className='px-5'>
                        {(selectedLanguage === "en") ?
                        "Your application is submitted, and you’re one step closer to growing with us at MXB. Check your email for confirmation."
                        :
                        "شكرا لك على تواصلك مع شركتنا لقد استقبلنا رسالتك وسوف يتواصل معك أحد موظفي فريق مكسب!"
                        }
                        </p>
                        <button className='close-btn' onClick={() => setModalShow(false)} >Ok</button>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        
                    </Modal.Footer> */}
                    </Modal>
                    
            </section>
        </>
    )
}

export default CareerContactForm