import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './realstatebody.css'
//import { NavLink } from 'react-router-dom';

import rsimg from '../images/rs-about.webp'
// import sets1 from '../images/sets1.webp'
import sets2 from '../images/sets2.webp'
import sets3 from '../images/sets3.webp'
import sets4 from '../images/sets4.webp'
import sets5 from '../images/sets5.webp'


import setsmb1 from '../images/setsmb1.webp'

// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

// AOS.init({
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 450, // values from 0 to 3000, with step 50ms
//     easing: 'ease-in', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'bottom-bottom', // defines which position of the element regarding to window should trigger the animation
// });


// import bottomleft from '../images/bottom-left.webp'

const RealStateBody = ({selectedLanguage}) => {

    const data = [
        { 
            id: 1, 
            img: setsmb1, 
            heading: "Modern amenities", 
            para: 'We focus on seamlessly blending functionality with convenience and comfort, to enhance the overall living experience in our properties.' ,
            animation: "fade-right"
        },
        { 
            id: 2, 
            img: sets2, 
            heading: "State-of-the-art infrastructure", 
            para: 'From advanced utility systems to connectivity, we believe that a robust infrastructure is critical to building thriving communities.' ,
            animation: "fade-left"
        },
        { 
            id: 3, 
            img: sets3, 
            heading: "Latest technology", 
            para: 'We equip our properties with cutting-edge technology to deliver superior comfort, convenience, connectivity and security.',
            animation: "fade-right"
         },
        { 
            id: 4, 
            img: sets4, 
            heading: "Sustainable development", 
            para: 'We are committed to creating eco-friendly communities by incorporating sustainable design principles, energy-efficient feature and responsible construction practices in development.',
            animation: "fade-left"
         },
        { 
            id: 5, 
            img: sets5, 
            heading: "Innovative architecture", 
            para: 'We build structures that are not only visually striking but also functional and sustainable helping us achieve our vision of inspiring and uplifting.',
            animation: "fade-up"
         },
    ];


    const dataar = [
        { 
            id: 1, 
            img: setsmb1, 
            heading: "وسائل الراحة المعاصرة", 
            para: 'نركز على أن نجمع بين أفضل استخدام للمساحات حسب الاستخدام مع الراحة في البناء لنجعل تجربة المعيشة في مشروعاتنا العقارية فريدةٌ من نوعها.' ,
            animation: "fade-left"
        },
        { 
            id: 2, 
            img: sets2, 
            heading: "بنية تحتية حديثة ", 
            para: 'نؤمن بأن البنية التحتية المتينة متطلب رئيسي لبناء مجتمعات مزدهرة، لذلك نقدم أفضل أنظمة المرافق وأحدث وسائل الإتصال.' ,
            animation: "fade-right"
        },
        { 
            id: 3, 
            img: sets3, 
            heading: "أحدث التقنيات", 
            para: 'نجهز عقاراتنا بأحدث التقنيات لتوفير أقصى درجات الراحة وسهولة الحياة والتواصل والأمن.',
            animation: "fade-left"
         },
        { 
            id: 4, 
            img: sets4, 
            heading: "التنمية المستدامة", 
            para: 'نلتزم بخلق مجتمعات صديقة للبيئة من خلال دمج مبادئ التصميم المستدام والميزات الموفرة للطاقة وممارسات البناء المسؤولة في جميع مراحل التطوير العقاري.',
            animation: "fade-right"
         },
        { 
            id: 5, 
            img: sets5, 
            heading: "الهندسة المعمارية المبتكرة", 
            para: 'لا تلفت عقاراتنا النظر فحسب، بل يمتاز كل جزء منها بمهام وظيفية ويلعب دوراً في استدامة العقار مما يساعدنا على نشر ثقافة الإلهام والروح الإيجابية في المجتمع.',
            animation: "fade-up"
         },
    ];

    return (
        <>
            <section className={(selectedLanguage === "en") ? "rsbody-section" : "rsbody-section ar"}>
                <Container>
                    <Row>
                        <div className='text-box text-center'>
                            {/* <div className='topright'><img src={topright} alt='' /></div>
                        <div className='bottomleft'><img src={bottomleft} alt='' /></div> */}
                            <Container>
                                <div className='desktop'>
                                    <Row className=''>
                                        <div className='col-md-5'>
                                            <img src={rsimg} alt='' />
                                        </div>
                                        <div className='col-md-6 px-5 mx-auto'>
                                        {(selectedLanguage === "en") ? 
                                        <>
                                             <h2>Empowering <br/>Growth and Progress</h2>
                                            <p className='mt-5'>Firmly aligned with the Saudi Vision 2030 of economic diversification and sustainable development, we have been building upon the strong foundation set by our leaders. Our exceptional portfolio of properties has been meticulously selected, designed and developed to embody sustainable practices and visionary architecture.</p>
                                       </> : 
                                         <>
                                             <h2>تمكين النمو والتقدم</h2>
                                            <p className='mt-5'>نعمل على تطوير أعمالنا بالتماشي مع أهداف رؤية السعودية 2030 للتنويع الاقتصادي والتنمية المستدامة وبالارتكاز على الأسس المتينة التي وضعها قادتنا، وعليه قمنا باختيار مجموعتنا الفريدة من العقارات بدقة وتصميمها وتطويرها لتجسيد الممارسات المستدامة والهندسة المعمارية المبتكرة.</p>
                                         </> 
                                        }
                                        </div>
                                    </Row>
                                </div>

                                <div className='mobile'>
                                    <Row className=''>
                                        <div className='col-md-12'>
                                            <div className='image-heading'>
                                                 <img src={rsimg} alt='' />
                                                {(selectedLanguage === "en") ? 
                                                    <>
                                                       <h2>Empowering <br/>Growth and Progress</h2>
                                                    </> :
                                                    <>
                                                       <h2>تمكين النمو والتقدم</h2>
                                                    </>
                                                }
                                            </div>
                                           
                                        </div>
                                        <div className='col-md-12 mx-auto'>
                                            {(selectedLanguage === "en") ? 
                                                <>
                                                   <p className='mt-3'>Firmly aligned with the Saudi Vision 2030 of economic diversification and sustainable development, we have been building upon the strong foundation set by our leaders. Our exceptional portfolio of properties has been meticulously selected, designed and developed to embody sustainable practices and visionary architecture.</p>
                                                </> :
                                                <>
                                                    <p className='mt-3'>نعمل على تطوير أعمالنا بالتماشي مع أهداف رؤية السعودية 2030 للتنويع الاقتصادي والتنمية المستدامة وبالارتكاز على الأسس المتينة التي وضعها قادتنا، وعليه قمنا باختيار مجموعتنا الفريدة من العقارات بدقة وتصميمها وتطويرها لتجسيد الممارسات المستدامة والهندسة المعمارية المبتكرة .</p>
                                                </>
                                            }    
                                         </div>
                                    </Row>
                                </div>
                                
                            </Container>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className={(selectedLanguage === "en") ? "rs-sets-section" : "rs-sets-section ar"}>
                <Container>
                    <Row>
                        <div className='text-box text-center'>
                            <Container>
                            {(selectedLanguage === "en") ? (<> 
 
                                <Row className='header'>
                                    <h2>What Sets Us Apart</h2>
                                    <p>Committed to building purposeful infrastructure</p>
                                
                                </Row>
                                <Row className='justify-content-center g-5 my-3'>

                                    {data.map((d, i) => (
                                        <div key={d.id} className='col-md-5 mx-3  img-box'
                                        data-aos={d.animation} data-aos-offset="400" data-aos-easing="ease-in-sine"
                                        data-aos-anchor=".rs-sets-section" 
                                        data-aos-anchor-placement='top-center' >
                                                <div className='inner-img-div'>
                                                    <img src={d.img} alt='' />
                                                </div>
                                                <div>
                                                    <h2>{d.heading}</h2>
                                                    <p>{d.para}</p>
                                                </div>
                                        </div>

                                    ))}


                                    {/* <div className='col-md-5'>
                                <img src={rsimg} alt='' />
                            </div>
                            <div className='col-md-7 px-5 mx-auto'>
                                <h2>Lorem ipsum dolor sit amet, consectetur</h2>
                                <p className='mt-5'>Firmly aligned with the Saudi Vision 2030 of economic diversification and sustainable development, we have been building upon the strong foundation set by our leaders. Our exceptional portfolio of properties has been meticulously selected, designed and developed to embody sustainable practices and visionary architecture</p>
                            </div> */}
                                </Row>
 
                                </>) : (<> 

                                <Row className='header'>
                                    <h2>عوامل تميّزنا</h2>
                                    <p>نلتزم بإنشاء بنية تحتية لها أهداف أبعد مما تراه العين</p>
                                
                                </Row>
                                <Row className='justify-content-center g-5 my-3'>

                                    {dataar.map((d, i) => (
                                        <div key={d.id} className='col-md-5 mx-3  img-box'
                                        data-aos={d.animation} data-aos-offset="400" data-aos-easing="ease-in-sine"
                                        data-aos-anchor=".rs-sets-section" 
                                        data-aos-anchor-placement='top-center' >
                                                <div className='inner-img-div'>
                                                    <img src={d.img} alt='' />
                                                </div>
                                                <div>
                                                    <h2>{d.heading}</h2>
                                                    <p>{d.para}</p>
                                                </div>
                                        </div>

                                    ))}


                                    {/* <div className='col-md-5'>
                                <img src={rsimg} alt='' />
                            </div>
                            <div className='col-md-7 px-5 mx-auto'>
                                <h2>Lorem ipsum dolor sit amet, consectetur</h2>
                                <p className='mt-5'>Firmly aligned with the Saudi Vision 2030 of economic diversification and sustainable development, we have been building upon the strong foundation set by our leaders. Our exceptional portfolio of properties has been meticulously selected, designed and developed to embody sustainable practices and visionary architecture</p>
                            </div> */}
                                </Row>
                            
                                </>)}
                            
                            </Container>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default RealStateBody
