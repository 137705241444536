import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import Careerheader from '../component/Careerheader'
import Mxbadvantage from '../component/Mxbadvantage'
import GrowWithMxb from '../component/GrowWithMxb'
import CareerContactForm from '../component/CareerContactForm'
import Footer from '../component/Footer';

const Career = (props) => {
  return (
    <>
    <Helmet>
      <title>Careers</title>
      <meta name='description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div style={{overflowX:"hidden"}}>
      <Menu setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage}  />
      <Careerheader  selectedLanguage={props.selectedLanguage} />
      <Mxbadvantage selectedLanguage={props.selectedLanguage} />
      <GrowWithMxb selectedLanguage={props.selectedLanguage} />
      <CareerContactForm selectedLanguage={props.selectedLanguage} />
      <Footer setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} />
    </div>
    </>
  )
}

export default Career