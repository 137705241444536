import * as Yup from "yup";

export const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your Name"),
  // jobrole: Yup.string().min(4).required("Please enter Job Role"),
  company: Yup.string().min(2).max(50).required("Please enter your Company"),
  jobtitle: Yup.string().min(2).max(25).required("Please enter Job Title"),
  nationality: Yup.string().required("Please select your Country"),
  email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
  // phone: Yup.string().min(10).max(10).required("Please enter your Phone Number"),
  // message: Yup.string().min(4).required("Please enter Message"),
  // resume: Yup .mixed().required("Please Select Resume"),
  //agree: Yup.bool().oneOf([true], 'You must accept the terms and conditions')
});

