import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import Investmentheader from '../component/Investmentheader'
import ExploreInvestment from '../component/ExploreInvestment'
import InvestmentPortfolio from '../component/InvestmentPortfolio'
import Footer from '../component/Footer';

const Investment = (props) => {
    return (
        <>
        <Helmet>
            <title>Investments</title>
            <meta name='description' content=''/>
            <meta name='keywords' content=''/>
        </Helmet>
            <Menu setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage} />
            <Investmentheader selectedLanguage={props.selectedLanguage}  />
            <InvestmentPortfolio selectedLanguage={props.selectedLanguage} />
            <ExploreInvestment setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} />
            <Footer setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} />
        </>
    )
}

export default Investment