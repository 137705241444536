import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import './footer.css'
import img1 from '../images/logos_whatsapp-icon.webp'
import img2 from '../images/skill-icons_instagram.webp'
import img3 from '../images/devicon_linkedin.webp'
import logo from '../images/mxb_logo.webp'
import { NavLink } from 'react-router-dom';


const Footer = ({setBlank,selectedLanguage}) => {
    
    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
            setBlank(false);            
        }, 800);
    }

    const d = new Date();
    let year = d.getFullYear();

    return (
        <>
            <footer className={selectedLanguage === "en" ? 'footer-section desktop' : 'footer-section ar desktop'}>
                <Container fluid className='footer-container'>
                    <Row className='top-footer'>
                        <Col className='col-md-3 logo text-center'>
                          <div>
                            <NavLink to='/'  onClick={scrollToTop} ><img src={logo} alt=''/></NavLink>
                          </div>
                        </Col>
                        <Col className='col-md-7 menu-container'>
                        {selectedLanguage === "en" ? <>
                            <ul>
                                <li><NavLink to='/about' onClick={scrollToTop} >About Us</NavLink> </li>
                                <li><NavLink to='/realestate' onClick={scrollToTop} >Real Estate</NavLink> </li>
                                <li><NavLink to='/investments' onClick={scrollToTop} >Investments</NavLink></li> 
                                <li><NavLink to='/careers' onClick={scrollToTop} >Careers</NavLink> </li>
                                <li><NavLink to='/contact' onClick={scrollToTop} > Contact Us</NavLink> </li>
                                <li><NavLink to='/privacypolicy' onClick={scrollToTop} >Privacy Policy </NavLink> </li>
                                <li><NavLink to='/terms-and-conditions' onClick={scrollToTop} >Terms & Conditions</NavLink></li> 
                            </ul>
                        </> : <>
                            <ul>
                                <li><NavLink to='/about' onClick={scrollToTop} >نبذة عنا</NavLink> </li>
                                <li><NavLink to='/realestate' onClick={scrollToTop} >العقارات</NavLink> </li>
                                <li><NavLink to='/investments' onClick={scrollToTop} >الاستثمارات</NavLink></li> 
                                <li><NavLink to='/careers' onClick={scrollToTop} >الوظائف</NavLink> </li>
                                <li><NavLink to='/contact' onClick={scrollToTop} > اتصل بنا</NavLink> </li>
                                <li><NavLink to='/privacypolicy' onClick={scrollToTop} >سياسة الخصوصية </NavLink> </li>
                                <li><NavLink to='/terms-and-conditions' onClick={scrollToTop} >شروط الاستخدام</NavLink></li> 
                            </ul>
                        </>}
                            
                        </Col>
                        <Col className='col-md-2 social-media-icon'>
                            <div className='social-media-div'>Connect with us at</div>
                            <ul>
                                <li>
                                    <a href="https://wa.me/966555811984" target='blank'><img src={img1} alt='' /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/mxb_investment/" target='blank'><img src={img2} alt='' /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/maksab-investment-company/" target='blank'><img src={img3} alt='' /></a>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                </Container>
                <Container fluid>
                    <Row className='middle-footer'>
                        <Col className='col-md-8 mx-auto'>
                        {selectedLanguage === "en" ? 
                        <>
                            <p className='copyright-text'>© {year}. All rights reserved. MXB Investment Company</p>                        
                        </> : <>
                            <p className='copyright-text'> جميع الحقوق محفوظة لشركة مكسب للاستثمار  {year} ©</p>                        
                        </>}                    
                        </Col>
                    </Row>
                </Container>
            </footer>

            <footer className={selectedLanguage === "en" ? 'footer-section mobile' : 'footer-section ar mobile'}>
                <Container fluid className='footer-container'>
                    <Row className='top-footer'>
                        <Col className='col-md-12 logo text-center'>
                          <div>
                            <NavLink to='/'  onClick={scrollToTop} ><img src={logo} alt=''/></NavLink>
                          </div>
                        </Col>
                    </Row>
                    <Row className='links-row'>
                    {selectedLanguage === "en" ? <>
                        <Col className='col-sm-6 menu-container'>
                            <ul>
             
                                <li><NavLink to='/about' onClick={scrollToTop} >About Us</NavLink> </li>
                                <li><NavLink to='/investments' onClick={scrollToTop} >Investments</NavLink></li> 
                                <li><NavLink to='/contact' onClick={scrollToTop} > Contact Us</NavLink> </li>
                                <li><NavLink to='/terms-and-conditions' onClick={scrollToTop} >Terms & Conditions</NavLink></li> 
                            </ul>
                        </Col>
                        <Col className='col-sm-6 menu-container'>
                            <ul>
                    
                                <li><NavLink to='/realestate' onClick={scrollToTop} >Real Estate</NavLink> </li>
                                <li><NavLink to='/careers' onClick={scrollToTop} >Careers</NavLink> </li>
                                <li><NavLink to='/privacypolicy' onClick={scrollToTop} >Privacy Policy </NavLink> </li>         
                            </ul>
                        </Col>
                        </> : <>
                        <Col className='col-sm-6 menu-container'>
                            <ul>
             
                                <li><NavLink to='/about' onClick={scrollToTop} >نبذة عنا</NavLink> </li>
                                <li><NavLink to='/investments' onClick={scrollToTop} >الاستثمارات</NavLink></li> 
                                <li><NavLink to='/contact' onClick={scrollToTop} > اتصل بنا</NavLink> </li>
                                <li><NavLink to='/terms-and-conditions' onClick={scrollToTop} >شروط الاستخدام</NavLink></li> 
                            </ul>
                        </Col>
                        <Col className='col-sm-6 menu-container'>
                            <ul>
                    
                                <li><NavLink to='/realestate' onClick={scrollToTop} >العقارات</NavLink> </li>
                                <li><NavLink to='/careers' onClick={scrollToTop} >اتصل بنا </NavLink> </li>
                                <li><NavLink to='/privacypolicy' onClick={scrollToTop} >سياسة الخصوصية  </NavLink> </li>         
                            </ul>
                        </Col>
                        
                        </>
                    }       
                    </Row>    
                    <Row className='mt-4'>    
                     
                        <Col className='col-md-12 social-media-icon'>
                            <div className='icon-box'>
                             <div className='social-media-div'>Connect with us at</div>
                                <ul>
                                    <li>
                                        <a href="https://wa.me/966555811984" target='blank'><img src={img1} alt='' /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/mxb_investment/" target='blank'><img src={img2} alt='' /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/maksab-investment-company/" target='blank'><img src={img3} alt='' /></a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                </Container>
                <Container fluid>
                    <Row className='middle-footer'>
                        <Col className='col-md-8 mx-auto'>
                        <p className='copyright-text'>© {year}. All rights reserved. MXB Investment Company</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer