import React from 'react'
import './mxbadvantage.css'
import { Row, Container, Col } from 'react-bootstrap';
import img1 from '../images/mxbadv1.webp'
import img2 from '../images/mxbadv2.webp'
import img3 from '../images/mxbadvimg4.webp'
import topleft from '../images/mxbadvantage-top.webp'
import bottomright from '../images/mxbadvantage-bottom.webp'

import topleftmb from '../images/mxbadvantage-top-mb.webp'
import bottomrightmb from '../images/mxbadvantage-bottom-mb.webp'
const Mxbadvantage = ({selectedLanguage}) => {
    const dataen = [
        {
            "id": 1,
            "image": img1,
            "imgheading": "Unparalleled learning",
            "imgcontent": "We pay special emphasis on cultivating an environment that promotes synergy, collaboration and innovation. You will work closely with the leadership and get an opportunity to be a part of industry-shaping conversations and initiatives.",
            animation: "fade-down-right"
        },
        {
            "id": 2,
            "image": img2,
            "imgheading": "Sustainable impact creation",
            "imgcontent": "We strive to create a positive impact through our commitment to integrity, accountability, respect, and ethical investing. Our objective is to leverage our expertize, resources and influence to create a long-lasting impact.",
            animation: "fade-down"
        },
        {
            "id": 3,
            "image": img3,
            "imgheading": "Balance and inclusivity-driven work culture", 
            "imgcontent": "Individual well-being is central to MXB's culture. We value diverse perspectives, celebrate unique strengths and encourage building synergies.",
            animation: "fade-down-left"
        }
    ]

    const dataar = [
        {
            "id": 1,
            "image": img1,
            "imgheading": "تعلّم فريد من نوعه ",
            "imgcontent": "في إطار سعينا لخلق بيئة تعزز المشاركة والتعاون والإبداع، ستعمل بشكل مباشر مع جميع المستويات القيادية وستحصل على فرصة لتشارك في الحوارات والمبادرات التي تحدد أعمالنا في قطاع العقارات.",
            animation: "fade-down-left"
        },
        {
            "id": 2,
            "image": img2,
            "imgheading": "خلق تأثير مستدام ",
            "imgcontent": "نسعى جاهدين لخلق تأثير إيجابي من خلال التزامنا بالنزاهة والمساءلة والاحترام والاستثمار الأخلاقي، فهدفنا هو الاستفادة من خبراتنا ومواردنا لخلق تأثير طويل الأجل.",
            animation: "fade-down"
        },
        {
            "id": 3,
            "image": img3,
            "imgheading": "ثقافة العمل القائمة على التوازن والشمولية", 
            "imgcontent": "تمثل رفاهية الفرد أمراً أساسياً في ثقافة مكسب للاستثمار، حيث نُقدر وجهات النظر المختلفة ونسفيد من نقاط القوة الفردية ونشجع على التحالف والتآزر.",
            animation: "fade-down-right"
        }
    ]
    var data;
    if(selectedLanguage === "en" )  data = [...dataen]; else data = [...dataar];
    
    return (
        <>
            <section className={selectedLanguage === "en" ? 'mxbadvantage-section' : 'mxbadvantage-section ar'}>
                
                <div className='bottomright'
                 data-aos="fade-up" data-aos-offset="0" data-aos-easing="ease-in-sine"
                 data-aos-anchor=".mxbadvantage-section" 
                 >
                    <img className='desktop' src={bottomright} alt='' />
                    <img className='mobile' src={bottomrightmb} alt='' />
                </div>
                <div className='topleft'
                  data-aos="fade-down" data-aos-offset="0" data-aos-easing="ease-in-sine"
                  data-aos-anchor=".mxbadvantage-section" 
                >
                    <img className='desktop' src={topleft} alt='' />
                    <img className='mobile' src={topleftmb} alt='' />
                </div>

                <div>
                    <h4 className='mxbadv-heading '>
                        {selectedLanguage === "en" ? 'The MXB Advantage' : 'مزايا شركة مكسب للاستثمار'}
                    </h4>

                    <Row className='mt-lg-3'>

                        <Col className='col-md-8 mx-auto'>
                            <Container>
                                <Row>
                                    {
                                        data.map((d) => {
                                            return (
                                                <div key={d.id} className='col-md-4'
                                                        data-aos={d.animation} data-aos-offset="0" data-aos-easing="ease-in-sine"
                                                        data-aos-anchor=".mxbadvantage-section" data-aos-anchor-placement='top-center'
                                                    >
                                                        <div className='mxbadv-div'>
                                                            <div className='inner-div text-center'>
                                                                <img src={d.image} alt=''/>
                                                                <h4 className='img-heading mt-4'>{d.imgheading}</h4>
                                                                <p className='img-para'>{d.imgcontent}</p>
                                                            </div>
                                                        </div>

                                                </div>
                                            )
                                        })
                                    }
                                </Row >
                            </Container>
                        </Col>

                    </Row>

                </div>
            </section >
        </>
    )
}

export default Mxbadvantage