import React from 'react'
import './investmentportfolio.css'
import { Container, Row, Col } from 'react-bootstrap'
import rsimg from '../images/rs-investment.webp'
import masiclogo from '../images/masic-logo.webp'

// import img1 from '../images/ipimg1.webp'
// import img2 from '../images/ip2.webp'
// import img3 from '../images/ip3.webp'
// import img4 from '../images/ip4.webp'
// import img5 from '../images/ip5.webp'
// import img6 from '../images/ip6.webp'
// import img7 from '../images/ip7.webp'
// import img8 from '../images/ip8.webp'
// import img9 from '../images/ip9.webp'

import img1 from '../images/Jadwa-Investment.webp'
import logo1 from '../images/Jadwa-invesment-logo.webp'
import img2 from '../images/Albilad-Bank.webp'
import logo2 from '../images/bank_albilad_LOGO.webp'
import img3 from '../images/Alargan-Projects.webp'
import logo3 from '../images/Alargan-Projects-logo.webp'
import img4 from '../images/sas-company.webp'
import logo4 from '../images/sas-logo.webp'
import img5 from '../images/tatra-global.webp'
import logo5 from '../images/tatra-logo.webp'
import img6 from '../images/meem.webp'
import logo6 from '../images/meem-logo.webp'
import img7 from '../images/Naqua.webp'
import logo7 from '../images/Naqua-logo.webp'
import img8 from '../images/Thakher-Makkah.webp'
import logo8 from '../images/Thaker-logo.webp'
import img9 from '../images/Fajr-capital.webp'
import logo9 from '../images/Fajr-capital-logo.webp'
import img10 from '../images/ATMTA.webp'
import logo10 from '../images/ATMTA-logo.webp'
import img11 from '../images/Tasheed.webp'
import logo11 from '../images/Tasheed-logo.webp'
import img12 from '../images/Aid-medical.webp'
import logo12 from '../images/Aid-medical-logo.webp'

import preimg1 from '../images/Almeswak.webp'
import preimg1mb from '../images/Almeswak-mb.webp'
import prelogo1 from '../images/Almeswak-logo.webp'
import preimg2 from '../images/Gissah.webp'
import preimg2mb from '../images/Gissah-mb.webp'
import prelogo2 from '../images/Gissah-logo.webp'
import preimg3 from '../images/Blackspoon.webp'
import preimg3mb from '../images/Blackspoon-mb.webp'
import prelogo3 from '../images/Blackspoon-logo.webp'
import preimg4 from '../images/RegentsCrecent.webp'
import preimg4mb from '../images/RegentsCrecent-mb.webp'
import prelogo4 from '../images/RegentsCrecent-logo.webp'

import rimg1 from '../images/Safa-Najd.webp'
import rimg1mb from '../images/Safa-Najd-mb.webp'
import rlogo1 from '../images/Safa-Najd-logo.webp'
import rimg2 from '../images/Albilad-abhur.webp'
import rimg2mb from '../images/Albilad-abhur-mb.webp'
import rlogo2 from '../images/Safa-Najd-logo.webp'
import rimg3 from '../images/Bilad-alawali.webp'
import rimg3mb from '../images/Bilad-alawali-mb.webp'
import rlogo3 from '../images/Safa-Najd-logo.webp'
import rimg4 from '../images/prime-real-estate.webp'
import rimg4mb from '../images/prime-real-estate-mb.webp'
import rlogo4 from '../images/prime-real-estate-logo.webp'
import rimg5 from '../images/Arbah-US.webp'
import rimg5mb from '../images/Arbah-US-mb.webp'
import rlogo5 from '../images/Arbah-US-logo.webp'
import rimg6 from '../images/Tharawat-Residential.webp'
import rimg6mb from '../images/Tharawat-Residential-mb.webp'
// import rlogo6 from '../images/Tharawat-Residential-logo.webp'

import vcimglogo1 from '../images/impact46-logo.webp'
import vcimglogo2 from '../images/seedra-logo.svg'
import vcimg1 from '../images/Impact46.webp'
import vcimg2 from '../images/Seedra.webp'


import pubquiImg from '../images/public-equity.webp'
import pubquiImgmb from '../images/public-equity-mb.webp'


import portleft1 from '../images/port-left1.webp'
import portleft2 from '../images/port-left2.webp'
import portright1 from '../images/port-right1.webp'
import portright2 from '../images/port-right2.webp'

import portleft1mb from '../images/port-left1-mb.webp'
import portleft2mb from '../images/port-left2-mb.webp'


// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

// AOS.init({
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 450, // values from 0 to 3000, with step 50ms
//     easing: 'ease-in', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-center', // defines which position of the element regarding to window should trigger the animation
// });

const directEquity = [
  {
    "id": 1,
    "img": img1,
    "logo": logo1,
    "heading": "Jadwa Investment",
    "des": "(Capital markets)",
  },
  {
    "id": 2,
    "img": img2,
    "logo": logo2,
    "heading": "Albilad Bank",
    "des": "(Banking)",
  },
  {
    "id": 9,
    "img": img9,
    "logo": logo9,
    "heading": "Fajr Capital",
    "des": "(Investment Management)",
  },

  {
    "id": 7,
    "img": img7,
    "logo": logo7,
    "heading": "Naqua",
    "des": "(National Aquaculture Group)",
  },
  {
    "id": 8,
    "img": img8,
    "logo": logo8,
    "heading": "Thakher Makkah",
    "des": "(Real estate development)",
  },
  {
    "id": 3,
    "img": img3,
    "logo": logo3,
    "heading": "Alargan Projects",
    "des": "(Real estate development)",
  },
  {
    "id": 12,
    "img": img12,
    "logo": logo12,
    "heading": "Aid Medical",
    "des": "(Healthcare)",
  },

  {
    "id": 5,
    "img": img5,
    "logo": logo5,
    "heading": "TATRA Global",
    "des": "(Integrated facility management services)",
  },
  
  {
    "id": 6,
    "img": img6,
    "logo": logo6,
    "heading": "MEEM Facility Management",
    "des": "(Maintenance & operations)",
  },

  {
    "id": 10,
    "img": img10,
    "logo": logo10,
    "heading": "ATMTA",
    "des": "(Venture Capital)",
  },
  {
    "id": 11,
    "img": img11,
    "logo": logo11,
    "heading": "Tasheed",
    "des": "(Development)",
  },
  {
    "id": 4,
    "img": img4,
    "logo": logo4,
    "heading": "Saudi Advanced Services Company",
    "des": "(Automation)",
  },

]

const directEquityar = [
  {
    "id": 1,
    "img": img1,
    "logo": logo1,
    "heading": "جدوى للاستثمار",
    "des": "(إدارة الاستثمار)",
  },
  {
    "id": 2,
    "img": img2,
    "logo": logo2,
    "heading": "بنك البلاد",
    "des": "(الخدمات المصرفية)",
  },
  {
    "id": 9,
    "img": img9,
    "logo": logo9,
    "heading": "فجر كابيتال",
    "des": "(إدارة الاستثمار)",
  },

  {
    "id": 7,
    "img": img7,
    "logo": logo7,
    "heading": "نقوا",
    "des": "(المجموعة الوطنية للاستزراع المائي)",
  },
  {
    "id": 8,
    "img": img8,
    "logo": logo8,
    "heading": "ذاخر مكة",
    "des": "(التطوير العقاري)",
  },
  {
    "id": 3,
    "img": img3,
    "logo": logo3,
    "heading": "مشاريع الأرجان",
    "des": "(التطوير العقاري)",
  },
  {
    "id": 12,
    "img": img12,
    "logo": logo12,
    "heading": "أيد الطبية",
    "des": "(الرعاية الصحية)",
  },

  {
    "id": 5,
    "img": img5,
    "logo": logo5,
    "heading": "تترا العالمية",
    "des": "(خدمات إدارة المرافق المتكاملة)",
  },
  
  {
    "id": 6,
    "img": img6,
    "logo": logo6,
    "heading": "ميم لإدارة المرافق",
    "des": "(الصيانة والتشغيل)",
  },

  {
    "id": 10,
    "img": img10,
    "logo": logo10,
    "heading": "ATMTA",
    "des": "(صناديق رأس المال الجرئ)",
  },
  {
    "id": 11,
    "img": img11,
    "logo": logo11,
    "heading": "تشييد",
    "des": "(التطوير)",
  },
  {
    "id": 4,
    "img": img4,
    "logo": logo4,
    "heading": "لشركة السعودية للخدمات المتقدمة",
    "des": "(الأتمتة)",
  },

]


const RealEstateFunds = [
  {
    "id": 1,
    "img": rimg1,
    "imgmb": rimg1mb,
    "logo": rlogo1,
    "heading": "Safa Najd",
    "des": "(Fund managed by Albilad Capital)",
  },
  {
    "id": 2,
    "img": rimg2,
    "imgmb": rimg2mb,
    "logo": rlogo2,
    "heading": "Albilad Abhur",
    "des": "(Fund managed by Albilad Capital)",
  },
  {
    "id": 3,
    "img": rimg3,
    "imgmb": rimg3mb,
    "logo": rlogo3,
    "heading": "Bilad Alawali Real Estate",
    "des": "(Fund managed by Albilad Capital)",
  },
  {
    "id": 4,
    "img": rimg4,
    "imgmb": rimg4mb,
    // "logo": logo10,
    "logo": rlogo4,
    "heading": "Prime Real Estate",
    "des": "(Fund managed by Tharawat for Financial Securities)",
  },
  {
    "id": 5,
    "img": rimg5,
    "imgmb": rimg5mb,
    "logo": rlogo5,
    "heading": "Arbah US Office Ltd. - Siemens",
    "des": "(Real estate development) ",
  },
  {
    "id": 6,
    "img": rimg6,
    "imgmb": rimg6mb,
    // "logo": logo10,
    "logo": rlogo4,
    "heading": "Tharawat Residential 3rd fund",
    "des": "(Residential real estate development)",
  },
   
]

const RealEstateFundsar = [
  {
    "id": 1,
    "img": rimg1,
    "imgmb": rimg1mb,
    "logo": rlogo1,
    "heading": "صندوق صفا نجد",
    "des": "(صندوق تديره شركة البلاد المالية)",
  },
  {
    "id": 2,
    "img": rimg2,
    "imgmb": rimg2mb,
    "logo": rlogo2,
    "heading": "صندوق البلاد ابحر",
    "des": "(صندوق تديره شركة البلاد المالية)",
  },
  {
    "id": 3,
    "img": rimg3,
    "imgmb": rimg3mb,
    "logo": rlogo3,
    "heading": "صندوق البلاد العقاري العوالي",
    "des": "(صندوق تديره شركة البلاد المالية)",
  },
  {
    "id": 4,
    "img": rimg4,
    "imgmb": rimg4mb,
    // "logo": logo10,
    "logo": rlogo4,
    "heading": "صندوق العقارات برايم",
    "des": "(صندوق تديره شركة ثروات للأوراق المالية)",
  },
  {
    "id": 5,
    "img": rimg5,
    "imgmb": rimg5mb,
    "logo": rlogo5,
    "heading": "صندوق أرباح في الولايات المتحدة الأمريكية - مبنى مكاتب شركة سيمنز للطاقة",
    "des": "(الاستحواذ على عقار من فئة أ في ولاية فلوريدا)",
  },
  {
    "id": 6,
    "img": rimg6,
    "imgmb": rimg6mb,
    // "logo": logo10,
    "logo": rlogo4,
    "heading": "صندوق ثروات السكني الثالث",
    "des": "(تطوير عقارات سكنية)",
  },
   
]


const PrivateEquity = [
  {
    "id": 1,
    "img": preimg1,
    "imgmb": preimg1mb,
    "logo": prelogo1,
    "heading": "Almeswak",
    "des": "(Dental care and dermatology)",
  },
  {
    "id": 2,
    "img": preimg2,
    "imgmb": preimg2mb,
    "logo": prelogo2,
    "heading": "Gissah",
    "des": "(Fragrance company)",
  },
  {
    "id": 3,
    "img": preimg3,
    "imgmb": preimg3mb,
    "logo": prelogo3,
    "heading": "Blackspoon",
    "des": "(Restaurants operations and management company)",
  },
  {
    "id": 4,
    "img": preimg4,
    "imgmb": preimg4mb,
    "logo": prelogo4,
    "heading": "Regent’s Crescent Mezzanine",
    "des": "(Finance fund)",
  }
]

const PrivateEquityar = [
  {
    "id": 1,
    "img": preimg1,
    "imgmb": preimg1mb,
    "logo": prelogo1,
    "heading": "المسواك",
    "des": "(العناية بالأسنان والأمراض الجلدية)",
  },
  {
    "id": 2,
    "img": preimg2,
    "imgmb": preimg2mb,
    "logo": prelogo2,
    "heading": "قصة",
    "des": "(شركة عطور)",
  },
  {
    "id": 3,
    "img": preimg3,
    "imgmb": preimg3mb,
    "logo": prelogo3,
    "heading": "بلاك سبون",
    "des": "(شركة ادارة وتشغيل المطاعم)",
  },
  {
    "id": 4,
    "img": preimg4,
    "imgmb": preimg4mb,
    "logo": prelogo4,
    "heading": " تمويل الميزانين لمشروع ريجنت كرسنت",
    "des": "(صندوق تمويلي)",
  }
]


const VentureCapital = [
  {
    "id": 1,
    "img": vcimg1,
    "imgmb": vcimg1,
    "logo": vcimglogo1,
    "heading": "Impact46 ",
    "des": "",
  },
  {
    "id": 2,
    "img": vcimg2,
    "imgmb": vcimg2,
    "logo": vcimglogo2,
    "heading": "Seedra",
    "des": "",
  },
]



var directEquitydata,PrivateEquitydata,RealEstateFundsdata;

const InvestmentPortfolio = ({selectedLanguage}) => {
  if(selectedLanguage === "en") 
  {
     directEquitydata = [...directEquity];
     RealEstateFundsdata = [...RealEstateFunds];
     PrivateEquitydata = [...PrivateEquity]
  } else {
     directEquitydata = [...directEquityar];
     RealEstateFundsdata = [...RealEstateFundsar];
     PrivateEquitydata = [...PrivateEquityar]
  }
    
  return (
    <>
      <section>


        <Container fluid className={(selectedLanguage === "en") ? "investment-port-section" : "investment-port-section ar"}>
          <Row>
            <div className='text-box text-center'>
              {/* <div className='topright'><img src={topright} alt='' /></div>
                        <div className='bottomleft'><img src={bottomleft} alt='' /></div> */}
              <Container className='desktop'>
                <Row >
                  <div className='col-md-5'>
                    <img src={rsimg} alt='' />
                  </div>
                  <div className='col-md-6 px-0 mx-auto'>
                  {(selectedLanguage === "en") ? <>
                   
                      <h2>Creating Value,<br /> Investing with Integrity</h2>
                      <p className='mt-5'>At MXB, we are guided by an investment philosophy that revolves around driving capital appreciation through Shariah-compliant investments and maintaining diligently balanced risk profile. We adopt a long-term perspective and aim to generate returns that surpass the market average. Through rigorous analysis, strategic decision making and active portfolio management we navigate the dynamic market landscape to deliver value to all our stakeholders.</p>
                    </> : <>
                      <h2>بناء الثروة عن طريق الاستثمار بحكمة</h2>
                      <p className='mt-5'>تقودنا فلسفة الاستثمار في شركة مكسب للاستثمار للتركيز على زيادة قيمة رأس المال من خلال الاستثمارات المتوافقة مع أحكام ومبادئ الشريعة الإسلامية والحفاظ على مستوى مخاطر متوازن، كما نتبنى ثقافة الاستثمار طويل الأجل ونهدف إلى تحقيق عوائد تتجاوز ما هو متعارف عليه في السوق. نقدم الفائدة لجميع أصحاب المصالح ونتجاوز جميع الصعوبات في الاستثمار الحيويّ والمتغيّر بالتحليل الدقيق واتخاذ القرارات الاستراتيجية  بناء على مخاطر مدروسة والإدارة الفعّالة لأعمالنا.</p>
                  </>}
                  </div>
                </Row>

                </Container>
                <Container  className='mobile'>
                <Row>
                  <div className='col-md-12'>
                    <div className='heading-img relative'>
                      <img src={rsimg} alt='' />
                      <h2>
                      {(selectedLanguage === "en") ? "Creating Value, Investing with Integrity" :
                      "بناء الثروة عن طريق الاستثمار بحكمة"}</h2>
                    </div>
                    {(selectedLanguage === "en") ? 
                    <p>At MXB, we are guided by an investment philosophy that revolves around driving capital appreciation through Shariah-compliant investments and maintaining diligently balanced risk profile. We adopt a long-term perspective and aim to generate returns that surpass the market average. Through rigorous analysis, strategic decision making and active portfolio management we navigate the dynamic market landscape to deliver value to all our stakeholders.</p> 
                    : <p>تقودنا فلسفة الاستثمار في شركة مكسب للاستثمار للتركيز على زيادة قيمة رأس المال من خلال الاستثمارات المتوافقة مع أحكام ومبادئ الشريعة الإسلامية والحفاظ على مستوى مخاطر متوازن، كما نتبنى ثقافة الاستثمار طويل الأجل ونهدف إلى تحقيق عوائد تتجاوز ما هو متعارف عليه في السوق. نقدم الفائدة لجميع أصحاب المصالح ونتجاوز جميع الصعوبات في الاستثمار الحيويّ والمتغيّر بالتحليل الدقيق واتخاذ القرارات الاستراتيجية  بناء على مخاطر مدروسة والإدارة الفعّالة لأعمالنا.</p>}
                  </div>
                </Row>
              </Container>
            </div>
          </Row>
        </Container>
      </section>
      {/* <Container fluid className='investmentbotm-section'>
        <Row>
          <Col className='col-md-8 mx-auto'>
            <Container>
              <Row>
                <Col className='col-md-4'></Col>
                <Col className='col-md-8 pt-5 mt-4 mb-4 pb-5'>
                  <h3 className='investbtm-heading'>Building Wealth, Investing Wisely</h3>
                  <p className='investbtm-para'>At MXB, we are guided by an investment philosophy that revolves around driving capital appreciation through Shariah-compliant investments and maintaining a carefully balanced risk profile. We adopt a long-term perspective and aim to generate returns that surpass the market average. Through rigorous analysis, strategic decision making and active portfolio management we navigate the dynamic market landscape to deliver value to all our stakeholders</p>
                </Col>
              </Row>
            </Container>

          </Col>
        </Row>
      </Container> */}

      <section className={(selectedLanguage === "en") ? "investment-portfolio-section" : "investment-portfolio-section ar"} >
             <div className='portleft1'
               data-aos="fade-right" data-aos-offset="-200" data-aos-easing="ease-in-sine"
               data-aos-anchor=".direct-equity" data-aos-anchor-placement='top-center'
               >
                <img className='desktop' src={portleft1} alt='' />
                <img className='mobile' src={portleft1mb} alt='' />
             </div>
             
             <div className='portleft2'
               data-aos="fade-right" data-aos-offset="-200" data-aos-easing="ease-in-sine"
               data-aos-anchor=".public-equity-animation" data-aos-anchor-placement='top-center'
               >
                <img className='desktop' src={portleft2} alt='' />
                <img className='mobile' src={portleft2mb} alt='' />
              </div>

             <div className='portright1'
                data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                data-aos-anchor=".direct-equity" data-aos-anchor-placement='top-center'
              >
                 <img className='desktop' src={portright1} alt='' />
             </div>

             <div className='portright2'
                data-aos="fade-up" data-aos-offset="-200" data-aos-easing="ease-in-sine"
                data-aos-anchor=".real-estate-found" data-aos-anchor-placement='top-center'
              >
                 <img className='desktop' src={portright2} alt='' />
             </div>


        <Container>
          <Row>
            <div className='text-box text-center'>
              {/* <Container> */}

              {(selectedLanguage === "en") ? 
                <>
                  <Row className='header mt-5 pt-5'>
                    <h2>Our Investment Portfolio</h2>
                    <p>Building wealth, investing wisely</p>
                  </Row>              
                </> : <>
                  <Row className='header mt-5 pt-5'>
                    <h2>أعمالنا الاستثمارية </h2>
                    <p>بناء الثروة عن طريق الاستثمار بحكمة</p>
                  </Row>
                </> }

               
               <Row>
                  <Col>
                  
                  <div className='invest-portfolio direct-equity'>
                    
                     <div className='ip-header'>
                          <h5>{(selectedLanguage === "en") ? "Direct equity" : "الأسهم المباشرة "}</h5>
                          {/* <p>MXB's non-real estate portfolio consists of investments in various asset classes through MASIC</p> */}
                     </div>

                     <div className='ip-masic-box'
                      data-aos="fade-up" data-aos-offset="-200" data-aos-easing="ease-in-sine" 
                      data-aos-anchor=".direct-equity" data-aos-anchor-placement='top-center'
                      >
                          <img src={masiclogo} alt='' />
                          <p>
                          {(selectedLanguage === "en") ? 
                            "Mohammed I. Alsubeaei & Sons Investment Company" : 
                            "شركة محمد إبراهيم السبيعي وأولاده للإستثمار"}
                          </p>
                     </div>
                   
                    <div className='invest-portfolio-grid'>

                      {directEquitydata.map((d, i) => ( 

                        <div className='portfolio-box' key={d.id}
                          data-aos="fade-up" data-aos-offset="-200" data-aos-easing="ease-in-sine"
                          data-aos-anchor=".direct-equity" data-aos-anchor-placement='top-center'
                          >
                        <img className='bg-img' src={d.img} alt='' />
                        <div className='portfolio-des'>
                            <div className='prot-logo-box'>
                                <img src={d.logo} alt='' />
                            </div>
                            <h5>{d.heading}</h5>
                            <p>{d.des}</p>
                        </div> 
                        </div> 

                      ))}

                        {/* <div className='portfolio-box'>
                          <img src={img1} alt='' />
                           <div className='portfolio-des'>
                              <div className='prot-logo-box'>
                                  <img src={logo1} alt='' />
                              </div>
                              <h5>Jadwa Investment</h5>
                              <p>(Investment Management)</p>
                           </div> 
                        </div>  */}

                      </div>
                  </div>
                  
                  
                  </Col>
                </Row> 

                {/* Private Equity */}
                <Row>
                  <Col>
                  
                  <div className='invest-portfolio pb-low private-equity-animation'>
                      <div className='ip-header'>
                          <h5>{(selectedLanguage === "en") ? "Private equity" : "صناديق الأسهم الخاصة "}</h5>
                          {/* <p>MXB's non-real estate portfolio consists of investments in various asset classes through MASIC</p> */}
                     </div>
                   
                    <div className='invest-portfolio-grid private-equity'>

                      {PrivateEquitydata.map((d, i) => ( 

                        <div className='portfolio-box' key={d.id}
                        data-aos="fade-up" data-aos-offset="-200" data-aos-easing="ease-in-sine"
                        data-aos-anchor=".private-equity-animation" data-aos-anchor-placement='top-center'
                       >
                        <img className='bg-img desktop' src={d.img} alt='' />
                        <img className='bg-img mobile' src={d.imgmb} alt='' />
                        <div className='tr-logo' style={(d.id === 4) ? {display:"none"} : {}} > <img src={logo1} alt='' /></div>
                        <div className='portfolio-des'>
                            <div className='prot-logo-box'>
                                <img src={d.logo} alt='' />
                            </div>
                            <h5>{d.heading}</h5>
                            <p>{d.des}</p>
                        </div> 
                        </div> 

                      ))}

                        {/* <div className='portfolio-box'>
                          <img src={img1} alt='' />
                           <div className='portfolio-des'>
                              <div className='prot-logo-box'>
                                  <img src={logo1} alt='' />
                              </div>
                              <h5>Jadwa Investment</h5>
                              <p>(Investment Management)</p>
                           </div> 
                        </div>  */}

                      </div>
                  </div>
                  
                  
                  </Col>
                </Row>        

                <Row>
                  <Col>
                  
                  <div className='invest-portfolio pb-low public-equity-animation'>
                      <div className='ip-header'>
                          <h5>{(selectedLanguage === "en") ? "Public equity" : "الأسهم العامة"}</h5>
                          {/* <p>MXB's non-real estate portfolio consists of investments in various asset classes through MASIC</p> */}
                     </div>
                   
                    <div className='invest-portfolio-grid public-equity'>
                    
                      <div className='portfolio-box'
                        data-aos="fade-up" data-aos-offset="-200" data-aos-easing="ease-in-sine"
                        data-aos-anchor=".public-equity-animation" data-aos-anchor-placement='top-center'
                      >
                        <img className='bg-img desktop' src={pubquiImg} alt='' />
                        <img className='bg-img mobile' src={pubquiImgmb} alt='' />
                        <div className='portfolio-des'>
                            
                            {(selectedLanguage === "en") ? 
                            <><p className='px-5'><b>MXB</b> engages in strategic investments in publicly traded equities through its Discretionary Portfolio Management (DPM) service, expertly overseen by top-tier portfolio managers who are renowned for their exceptional skills and expertise.</p></> : 
                            <><p className='px-5'>تشارك مكسب للاستثمار في استثمارات استراتيجية في الأسهم المتداولة بشكل عام من خلال  إدارة المحافظ  المختصين (DPM)، حيث تتعامل شركة مكسب للإستثمار مع عدةمدراء محافظ يتميزون بمهاراتهم الاستثنائية وخبرتهم الطويلة بالاضافة الى الاداء التاريخي التنافسي.</p></>}
                        </div> 
                      </div> 

                      </div>
                  </div>
                  
                  
                  </Col>
                </Row> 

                <Row>
                  <Col>
                  
                  <div className='invest-portfolio pb-low real-estate-animation'>
                      <div className='ip-header'>
                          <h5>{(selectedLanguage === "en") ? "Real Estate Funds" : "الصناديق العقارية"}</h5>
                          {/* <p>MXB's non-real estate portfolio consists of investments in various asset classes through MASIC</p> */}
                     </div>
                   
                    <div className='invest-portfolio-grid real-estate-found'>

                      {RealEstateFundsdata.map((d, i) => ( 

                        <div className='portfolio-box' key={d.id}
                        data-aos="fade-up" data-aos-offset="-200" data-aos-easing="ease-in-sine"
                        data-aos-anchor=".real-estate-found" data-aos-anchor-placement='top-center'
                       >
                        <img className='bg-img desktop' src={d.img} alt='' />
                        <img className='bg-img mobile' src={d.imgmb} alt='' />
                        <div className='portfolio-des'>
                            <div className='prot-logo-box'>
                                <img src={d.logo} alt='' />
                            </div>
                            <h5>{d.heading}</h5>
                            <p>{d.des}</p>
                        </div> 
                        </div> 

                      ))}

                        {/* <div className='portfolio-box'>
                          <img src={img1} alt='' />
                           <div className='portfolio-des'>
                              <div className='prot-logo-box'>
                                  <img src={logo1} alt='' />
                              </div>
                              <h5>Jadwa Investment</h5>
                              <p>(Investment Management)</p>
                           </div> 
                        </div>  */}

                      </div>
                  </div>
                  
                  
                  </Col>
                </Row> 
          
                <Row>
                  <Col>
                  
                  <div className='invest-portfolio pb-low real-estate-animation'>
                      <div className='ip-header'>
                          <h5>{(selectedLanguage === "en") ? "Venture Capital" : "Venture Capital"}</h5>
                          {/* <p>MXB's non-real estate portfolio consists of investments in various asset classes through MASIC</p> */}
                     </div>
                   
                    <div className='invest-portfolio-grid venture-capital'>

                      {VentureCapital.map((d, i) => ( 

                        <div className='portfolio-box' key={d.id}
                        data-aos="fade-up" data-aos-offset="-200" data-aos-easing="ease-in-sine"
                        data-aos-anchor=".venture-capital" data-aos-anchor-placement='top-center'
                       >
                        <img className='bg-img desktop' src={d.img} alt='' />
                        <img className='bg-img mobile' src={d.imgmb} alt='' />
                        <div className='portfolio-des'>
                            <div className='prot-logo-box'>
                                <img src={d.logo} alt='' />
                            </div>
                            <h5>{d.heading}</h5>
                            <p>{d.des}</p>
                        </div> 
                        </div> 

                      ))}

                      </div>
                  </div>
                  
                  
                  </Col>
                </Row> 

              {/* </Container> */}
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default InvestmentPortfolio