import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import './explorerealestate.css'
import img1 from '../images/explorerealimg.webp'
import img1mb from '../images/explorerealimg-mb.webp'

const ExploreRealestate = ({selectedLanguage,setBlank}) => {

    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
            setBlank(false);            
        }, 900);
    }
    
  return (
    <> 
            <section className={(selectedLanguage === "en") ? "explore-realestate-section  desktop" : "explore-realestate-section  desktop ar"} >
                <Container>
                    <Row>
                        <Col className=''>
                            <img src={img1} alt='' className='explore-real-image' />
                        </Col>
                    </Row>
                    <div className='explore-real-container  w-100'>
                    <Row>
                            {(selectedLanguage === "en") ? (<>
                                <Col className='col-md-7'>
                                    <h1 className='explore-real-heading'>
                                    {/* Explore Real Estate opportunities with<br/><span className='fw-bold'>MXB</span> */}
                                    Get In Touch
                                    </h1>
                                    <NavLink to="/contact" onClick={scrollToTop}>
                                    <button className='contact-btn'>
                                        Contact Us
                                    </button>
                                    </NavLink>                               
                                </Col>
                   
                                </>) : (<>
                                <Col className='col-md-4 text-end'>
                                    <h1 className='explore-real-heading'>
                                    اكتشف الفرص العقارية مع شركة مكسب للاستثمار								
                                    </h1>
                    
                                    <NavLink to="/contact" onClick={scrollToTop}>
                                    <button className='contact-btn'>
                                         تواصل معنا
                                    </button>
                                        </NavLink>                               
                                </Col>
                            </>)}
                            
                    </Row>   
                    </div>
             
                </Container>

            </section>

            <section className='explore-realestate-section mobile'>
                <Container fluid className='p-0 m-0'>
                     <Row className='g-0'>
                        <Col className='col-md-12'>
                         <div  className='img-text'>
                           <h1 className='explore-real-heading'>
                           {(selectedLanguage === "en") ? (<>
                            {/* Explore Real Estate opportunities with<br/><span className='fw-bold'>MXB</span>  */}
                            Get In Touch
                           </>) : (<>
                               اكتشف الفرص العقارية مع شركة مكسب للاستثمار			
                           </>) }
                           </h1>
                            <img src={img1mb} alt='' className='explore-real-image' />
                            <button className='contact-btn'>
                            {(selectedLanguage === "en") ? (<>
                                Contact Us
                            </>) : (<>
                                تواصل معنا
                            </>) }</button>
                         </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
  )
}

export default ExploreRealestate