import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import Homeheader from '../component/Homeheader'
import Mxbinvestment from '../component/Mxbinvestment';
//import Valuesystem from '../component/Valuesystem';
import Ourvaluesystem from '../component/Ourvaluesystem';
import Estate from '../component/Estate';
import Career from '../component/Career';
import Footer from '../component/Footer';

const Homepage = (props) => {

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name='description' content=''/>
        <meta name='keywords' content=''/>
      </Helmet>
      <Menu  setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage}  />
      <Homeheader selectedLanguage={props.selectedLanguage} />
      <Mxbinvestment setBlank={props.setBlank} selectedLanguage={props.selectedLanguage}  />
      <Ourvaluesystem selectedLanguage={props.selectedLanguage}  />
      <Estate setBlank={props.setBlank} selectedLanguage={props.selectedLanguage}  />
      <Career setBlank={props.setBlank} selectedLanguage={props.selectedLanguage}  />
      <Footer setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} />
    </>
  )
}

export default Homepage