import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import leftbottom from '../images/about-bottom.webp'
import righttop from '../images/about-top.webp'

import leftbottommb from '../images/about-bottom-mb.webp'
import righttopmb from '../images/about-top-mb.webp'
import './aboutdesk.css'

// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

// AOS.init({
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 450, // values from 0 to 3000, with step 50ms
//     easing: 'ease-in', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
// });


const AboutDesk = ({selectedLanguage}) => {
    return (
        <>
            <section className={(selectedLanguage === "en") ? "aboutdesk-section" : "aboutdesk-section ar"}>
                    <div className='leftbottom '>
                        <div data-aos="fade-right" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom">
                            <img className='desktop' src={leftbottom} alt='' />
                            <img className='mobile' src={leftbottommb} alt='' />
                        </div>
                    </div>
                    
                    <div className='righttop '>
                        <div data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine"   >
                            <img className='desktop' src={righttop} alt='' />
                            <img className='mobile' src={righttopmb} alt='' />
                        </div>    
                    </div>

                <Container>
                    <Row>
                        <div className='img-text text-center'>
                            
                            <Container>
                                <Row>
                                    <Col className='col-md-9'>
                                        <h2 className='img-heading'>
                                        {(selectedLanguage === "en") ? (<>From the <br /> Chairman’s desk </>) : 
                                        (<>كلمة رئيس مجلس الإدارة </>)}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-1 desktop'></Col>
                                    <Col className='col-md-11'>
                                        
                                    {(selectedLanguage === "en") ? (
                                        <> 
                                        
                                        <p className='img-para desktop'>
                                            <b>In The Name of Allah, The Most Beneficent, The Most Merciful.  </b><br /><br />
                                            The foundation of the company was laid in 1984 on its core values - integrity, professionalism, and dedication. These values have remained at the heart of the company's operations and have contributed to its success over the years.<br /><br />
                                            Acknowledging the opportunities presented by the dynamic and future-oriented Kingdom of Saudi Arabia’s Vision 2030 and the Kingdom's fast-paced advancement, we rebranded in 2021 as MXB Investment Company with the aim of contributing our part to realizing this vision in the Kingdom and across all asset classes where we invest.<br /><br />
                                            MXB is a Shariah-compliant investment firm, and we adhere to our principles and traditional family values. Our goal is to create long-term value for our shareholders by making sustainable investments in diverse asset classes while adhering to shariah principles.<br /><br />
                                            As a next generation enterprise, we also encourage leadership in our business and prioritize diversity and inclusion in our team. We believe that our success is not only measured by financial gains but also by the positive impact we make on the communities we operate in. At MXB, we are committed to creating a better future for all stakeholders and contributing to the growth and development of our people, communities, and nation.<br /><br />
                                        </p>
                                        <p className='sub-para desktop'>
                                            Sincerely, <br /><br />
                                            Mrs. Nada Mohammed Alsubeaei<br />
                                            Chairman, MXB Investment Company</p> 

                                        <p className='img-para mobile'>
                                            <b>In The Name of Allah, The Most Beneficent, The Most Merciful.  </b><br /><br />
                                            The foundation of the company was laid in 1984 on its core values - integrity, professionalism, and dedication. These values have remained at the heart of the company's operations and have contributed to its success over the years.<br />
                                            Acknowledging the opportunities presented by the dynamic and future-oriented Kingdom of Saudi Arabia’s Vision 2030 and the Kingdom's fast-paced advancement, we rebranded in 2021 as MXB Investment Company with the aim of contributing our part to realizing this vision in the Kingdom and across all asset classes where we invest.<br />
                                            MXB is a Shariah-compliant investment firm, and we adhere to our principles and traditional family values. Our goal is to create long-term value for our shareholders by making sustainable investments in diverse asset classes while adhering to shariah principles.<br />

                                            As a next generation enterprise, we also encourage leadership in our business and prioritize diversity and inclusion in our team. We believe that our success is not only measured by financial gains but also by the positive impact we make on the communities we operate in. At MXB, we are committed to creating a better future for all stakeholders and contributing to the growth and development of our people, communities, and nation.<br /><br />
                                        </p>                                    
                                        <p className='sub-para mobile'>
                                            Sincerely, <br /><br />
                                            Mrs. Nada Mohammed Alsubeaei<br />
                                            Chairman, MXB Investment Company</p> 

                                    
                                        </>
                                    ) : (
                                        <> 
                                    
                                        
                                        
                                    <p className='img-para desktop'>
                                            <b>بسم الله الرحمن الرحيم... </b><br /><br />
                                            أسسنا شركة مكسب للاستثمار عام 1984 وكانت لبنة الأساس مبنية على النزاهة والاحترافية العالية والالتزام المطلق، وحافظنا على هذه القيم في تنفيذ جميع عمليات الشركة على مدار تاريخها لكونها العامل الأكبر في نجاحنا على مدار السنين.<br /><br />
                                            وإدراكًا للفرص التي توفرها رؤية المملكة العربية السعودية الفعّالة والموجهة نحو المستقبل والتقدم السريع للمملكة، قمنا بتغيير علامتنا التجارية في عام 2021 لتصبح "شركة مكسب للاستثمار" لنساهم بكل ما أوتينا في تحقيق هذه الرؤية في المملكة وعبر جميع فئات الأصول التي نستثمر فيها.<br /><br />
                                            مكسب للاستثمار عبارة عن شركة استثمارية تعمل وفقًا لمبادئ وأحكام الشريعة الإسلامية، وتلتزم بالمبادئ والقيم العائلية الموروثة. ويتمثل هدفنا في خلق قيمة طويلة الأجل لمساهمينا من خلال تنفيذ استثمارات مستدامة في فئات الأصول المتنوعة حسب مبادئ وأحكام الشريعة الإسلامية.<br /><br />
                                            يضع الجيل الحالي من عائلة مكسب نصب أعينه التشجيع على الريادة في أعمالنا وترتكز أولوياتنا على التنوع والشمول الأوسع للمهارات والمواهب في فريقنا. نحن على يقين بأن نجاحنا لا يرتبط فقط بالمكاسب المالية، بل يمتد إلى الأثر الإيجابي الذي نتركه في المجتمعات التي نستثمر بها، كما أننا نلتزم في شركة مكسب للاستثمار بخلق مستقبل أفضل لجميع الأطراف والمساهمة في نمو الأفراد والمجتمعات والوطن.<br /><br />
                                        </p>
                                        <p className='sub-para desktop'>
                                        المخلصة لكم،<br /><br />
                                        ندى محمد السبيعي <br />
                                        رئيس مجلس إدارة شركة مكسب للاستثمار</p> 

                                        <p className='img-para mobile'>
                                            <b>بسم الله الرحمن الرحيم... </b><br /><br />
                                            أسسنا شركة مكسب للاستثمار عام 1984 وكانت لبنة الأساس مبنية على النزاهة والاحترافية العالية والالتزام المطلق، وحافظنا على هذه القيم في تنفيذ جميع عمليات الشركة على مدار تاريخها لكونها العامل الأكبر في نجاحنا على مدار السنين.<br /><br />
                                            وإدراكًا للفرص التي توفرها رؤية المملكة العربية السعودية الفعّالة والموجهة نحو المستقبل والتقدم السريع للمملكة، قمنا بتغيير علامتنا التجارية في عام 2021 لتصبح "شركة مكسب للاستثمار" لنساهم بكل ما أوتينا في تحقيق هذه الرؤية في المملكة وعبر جميع فئات الأصول التي نستثمر فيها.<br /><br />
                                            مكسب للاستثمار عبارة عن شركة استثمارية تعمل وفقًا لمبادئ وأحكام الشريعة الإسلامية، وتلتزم بالمبادئ والقيم العائلية الموروثة. ويتمثل هدفنا في خلق قيمة طويلة الأجل لمساهمينا من خلال تنفيذ استثمارات مستدامة في فئات الأصول المتنوعة حسب مبادئ وأحكام الشريعة الإسلامية.<br /><br />
                                            يضع الجيل الحالي من عائلة مكسب نصب أعينه التشجيع على الريادة في أعمالنا وترتكز أولوياتنا على التنوع والشمول الأوسع للمهارات والمواهب في فريقنا. نحن على يقين بأن نجاحنا لا يرتبط فقط بالمكاسب المالية، بل يمتد إلى الأثر الإيجابي الذي نتركه في المجتمعات التي نستثمر بها، كما أننا نلتزم في شركة مكسب للاستثمار بخلق مستقبل أفضل لجميع الأطراف والمساهمة في نمو الأفراد والمجتمعات والوطن.<br /><br />
                                        </p>                                    
                                        <p className='sub-para mobile'>
                                            المخلصة لكم،<br /><br />
                                            ندى محمد السبيعي <br />
                                            رئيس مجلس إدارة شركة مكسب للاستثمار</p>  
                                        </>
                                    )}

                                    </Col>

                                </Row>
                            </Container>
                        </div>
                    </Row>
                </Container>

            </section>
        </>
    )
}

export default AboutDesk