import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import './termsandconditoncomp.css'

const TermsAndConditonComp = ({selectedLanguage}) => {
    return (
        <>
            <section className={(selectedLanguage === "en") ? 'termsandconditon-section' : 'termsandconditon-section'}>
                <Container>
                    <Row>
                        <Col>

                              <p className='termsandconditon-para'>
                                <b>Welcome to the MXB Investment Company’s website (<a href="www.mxb.sa">www.mxb.sa</a>, 'the website').</b><br/><br/>
                                These Terms of Use are made between MXB Investment Company ('MXB', 'We', 'Our', 'Us') and users of this website (‘you','your') to govern your access to and use of the website (as defined below). By continuing to browse this website, you are agreeing to comply with and be bound by the following terms and conditions of use. The Terms of Use are to be read in conjunction with our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us.<br/><br/>
                                The Laws of the Kingdom of Saudi Arabia will govern these Terms of Use and the competent courts of the Kingdom of Saudi Arabia shall have exclusive jurisdiction over any disputes arising out of these.<br/><br/>
                                The content (including HTML, text, design, graphics, logos, icons, images, audio clips, and videos and the selection and arrangement thereof) of the pages of this website is for your general information and use only. It is subject to change without notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by the Law.<br/><br/>
                                Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
                                This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction of material is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.<br/><br/>

                                From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).<br/><br/>

                                You may not create a link to this website from another website or document without MXB Investment Company’s prior written consent.<br/><br/>
                                You agree not to interrupt or attempt to interrupt the operation of the website in any way. You are responsible for ensuring that your use of software, information and images obtained through your access of the website complies with all applicable laws.<br/><br/>
                                We do not warrant or guarantee that our website will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmers and platform to access our website. You should use your own virus protection software.<br/><br/>
                                You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to our website, the server on which our website is stored or any server, and computer or database connected to our website. By breaching this provision, you would commit under the laws of the Kingdom of Saudi Arabia and other countries. We will report any such breach to the relevant law enforcement authorities, and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our website will cease, immediately.<br/><br/>
                                We do not warrant or guarantee that our website will be secure, including from cyber-attacks, which may either seek to disrupt access to our website or steal data contained on our website. You access our website and submit any data to the website entirely at your own risk.
                                Your use of this website and any dispute arising out of such use of the website is subject to the laws of the Kingdom of Saudi Arabia.
                                </p>

                            {/* {(selectedLanguage === "en") ? <>
                                <p className='termsandconditon-para'>
                                <b>Welcome to the MXB Investment Company’s website (<a href="www.mxb.sa">www.mxb.sa</a>, 'the website').</b><br/><br/>
                                These Terms of Use are made between MXB Investment Company ('MXB', 'We', 'Our', 'Us') and users of this website (‘you','your') to govern your access to and use of the website (as defined below). By continuing to browse this website, you are agreeing to comply with and be bound by the following terms and conditions of use. The Terms of Use are to be read in conjunction with our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us.<br/><br/>
                                The Laws of the Kingdom of Saudi Arabia will govern these Terms of Use and the competent courts of the Kingdom of Saudi Arabia shall have exclusive jurisdiction over any disputes arising out of these.<br/><br/>
                                The content (including HTML, text, design, graphics, logos, icons, images, audio clips, and videos and the selection and arrangement thereof) of the pages of this website is for your general information and use only. It is subject to change without notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by the Law.<br/><br/>
                                Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
                                This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction of material is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.<br/><br/>

                                From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).<br/><br/>

                                You may not create a link to this website from another website or document without MXB Investment Company’s prior written consent.<br/><br/>
                                You agree not to interrupt or attempt to interrupt the operation of the website in any way. You are responsible for ensuring that your use of software, information and images obtained through your access of the website complies with all applicable laws.<br/><br/>
                                We do not warrant or guarantee that our website will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmers and platform to access our website. You should use your own virus protection software.<br/><br/>
                                You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to our website, the server on which our website is stored or any server, and computer or database connected to our website. By breaching this provision, you would commit under the laws of the Kingdom of Saudi Arabia and other countries. We will report any such breach to the relevant law enforcement authorities, and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our website will cease, immediately.<br/><br/>
                                We do not warrant or guarantee that our website will be secure, including from cyber-attacks, which may either seek to disrupt access to our website or steal data contained on our website. You access our website and submit any data to the website entirely at your own risk.
                                Your use of this website and any dispute arising out of such use of the website is subject to the laws of the Kingdom of Saudi Arabia.
                                </p>
                            </> : <>

                                <p className='termsandconditon-para'>
                                <b>مرحبًا بكم في الموقع الإلكتروني لشركة مكسب للاستثمار (<a href="www.mxb.sa">www.mxb.sa</a>، «الموقع الإلكتروني»).</b><br/><br/>
                                حُررت شروط الاستخدام هذه بين شركة مكسب للاستثمار («مكسب للاستثمار» و«نحن» و «الخاصة بنا» و«لنا») ومستخدمي هذا الموقع («أنت» و«الخاص بك») لتنظيم الوصول إلى الموقع واستخدامه (على النحو المحدد أدناه). بالاستمرار في تصفح هذا الموقع، فإنك توافق على الامتثال والالتزام بشروط وأحكام الاستخدام التالية. يجب قراءة شروط الاستخدام جنبًا إلى جنب مع سياسة الخصوصية الخاصة بنا، والتي تحدد الشروط التي نعالج بموجبها أي بيانات شخصية نجمعها منك، أو التي تقدمها لنا. <br/><br/>
                                وستحكم أنظمة المملكة العربية السعودية شروط الاستخدام هذه وستكون للمحاكم المختصة في المملكة العربية السعودية السلطة القضائية الحصرية على أي نزاعات تنشأ عن هذه الشروط.<br/><br/>
                                إن المحتوى (بما في ذلك HTML والنص والتصميم والرسومات والشعارات والأيقونات والصور ومقاطع الصوت ومقاطع الفيديو واختيارها وترتيبها) لصفحات هذا الموقع الإلكتروني مُعد لمعلوماتك العامة واستخدامك فقط. تخضع هذه الشروط للتغيير دون إشعار. لا نقدم نحن ولا أي طرف خارجي أي تأكيد أو ضمان فيما يتعلق بدقة أو توقيت أو أداء أو اكتمال أو ملاءمة المعلومات والمواد الموجودة أو المعروضة على هذا الموقع لأي غرض معين. تقر بأن هذه المعلومات والمواد قد تحتوي على معلومات غير دقيقة أو أخطاء ونستبعد صراحة المسؤولية عن أي عدم دقة أو أخطاء من هذا القبيل إلى أقصى حد يسمح به القانون.<br/><br/>
                                ن استخدامك لأي معلومات أو مواد على هذا الموقع يكون على مسؤوليتك الخاصة تمامًا، ولن نكون مسؤولين عنها. تقع على عاتقك مسؤولية التأكد من أن أي منتجات أو خدمات أو معلومات متاحة من خلال هذا الموقع تلبي متطلباتك الخاصة.<br/><br/>

                                يحتوي هذا الموقع على مواد مملوكة لنا أو مرخصة لنا. تتضمن هذه المواد، على سبيل المثال لا الحصر، التصميم والتخطيط والشكل والمظهر والرسومات. يحظر إعادة إنتاج المواد إلا وفقًا لإشعار حقوق التأليف والنشر، والذي يشكل جزءًا من هذه الشروط والأحكام.<br/><br/>

                                ومن حينٍ لآخر، قد يتضمن هذا الموقع الإلكتروني أيضًا روابط لمواقع إلكترونية أخرى. يتم توفير هذه الروابط لراحتك لتوفير مزيد من المعلومات. ولا تعني أننا نؤيد الموقع (المواقع) الإلكترونية. لا نتحمل أي مسؤولية عن محتوى موقع (مواقع) إلكترونية مرتبطة.<br/><br/>
                                ولا يجوز لك إنشاء رابط لهذا الموقع الإلكتروني من موقع إلكتروني أو مستند آخر دون موافقة كتابية مسبقة من شركة مكسب للاستثمار.<br/><br/>
                                توافق على عدم مقاطعة أو محاولة مقاطعة تشغيل الموقع الإلكتروني بأي شكل من الأشكال. تتحمل مسؤولية ضمان استخدامك للبرامج والمعلومات والصور التي تم الحصول عليها من خلال وصولك إلى موقع الويب وفقًا لجميع الأنظمة المعمول بها.<br/><br/>
                                لا نقر أو نضمن أن موقعنا الإلكتروني سيكون آمنًا أو خاليًا من الأخطاء أو الفيروسات. تتحمل مسؤولية تهيئة تكنولوجيا المعلومات ومبرمجي الكمبيوتر والنظام الأساسي للوصول إلى موقعنا الإلكتروني. وعليك استخدام برنامج الحماية من الفيروسات الملائم.<br/><br/>
                                يجب عليك عدم إساءة استخدام موقعنا الإلكتروني عن طريق إدخال فيروسات أو فيروسات حصان طروادة أو ديدان حاسوبية أو قنابل منطقية أو مواد أخرى مؤذية أو ضارة تكنولوجيًا. يجب الامتناع عن محاولة الوصول غير المصرح به إلى موقعنا الإلكتروني أو الخادم الذي يتم تخزين موقعنا عليه أو أي خادم وجهاز كمبيوتر أو قاعدة بيانات متصلة بموقعنا. بخرق هذا الشرط، فستلتزم بموجب أنظمة المملكة العربية السعودية والدول الأخرى. حيث سنتولى مهمة الإبلاغ عن أي خرق من هذا القبيل إلى سلطات إنفاذ القانون ذات الصلة وسنتعاون مع تلك السلطات من خلال الكشف عن هويتك لهم. وفي حالة حدوث مثل هذا الخرق، سيتوقف حقك في استخدام موقعنا على الفور.<br /><br />
                                لا نقر أو نضمن أن موقعنا الإلكتروني سيكون آمنًا من الهجمات الإلكترونية، والتي قد تسعى إما إلى تعطيل الوصول إلى موقعنا الإلكتروني أو سرقة البيانات الموجودة على موقعنا الإلكتروني. يمكنك الوصول إلى موقعنا الإلكتروني وإرسال أي بيانات إلى الموقع بالكامل على مسؤوليتك الخاصة.يخضع استخدامك لهذا الموقع الإلكتروني وأي نزاع ينشأ عن هذا الاستخدام لأنظمة المملكة العربية السعودية.
                            </p>
                            </>} */}


                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default TermsAndConditonComp