import React from 'react'
import './contactheader.css'
import { Row, Container, Col } from 'react-bootstrap';
const Contactheader = ({selectedLanguage}) => {
    return (
        <>
            <section>
                <Container fluid className='contactheader-section'>
                    <Row className='contact-inner-div'>
                        <Col className='col-md-12 mx-auto'>
                  
                            {selectedLanguage === "en" ? (
                                <>
                                <h1 className='contact-heading'>Get in <span className='fw-bold'>touch</span></h1>
                                <p className='contact-paragraph'>If you're interested in exploring partnerships or investment opportunities, please fill out this form and we'll be in touch to discuss potential synergies and collaborations.</p>
                                </>                           
 
                            ) : (
                                <>
                                <h1 className='contact-heading'>تواصل معنا</h1>
                                <p className='contact-paragraph'>إذا كنت مهتمًا بالتعرف على الشراكات أو فرص الاستثمار، فيُرجى ملء هذا النموذج وسنتواصل معك لمناقشة أوجه الشراكة والتعاون المحتملة.</p>
                            </>
                           )}
                        </Col>
                    </Row>
                    {/* <button className='learnmore-btn'>Learn More</button> */}
                </Container>
            </section>
        </>
    )
}

export default Contactheader