import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import Realestateheade from '../component/Realestateheade'
import RealStateBody from '../component/RealStateBody'
import Keyproject from '../component/Keyproject'
import ExploreRealestate from '../component/ExploreRealestate'
import Footer from '../component/Footer';

const RealEstate = (props) => {
  return (
  <>
    <Helmet>
      <title>Real Estate</title>
      <meta name='description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div className='overflow-hidden'>
    <Menu setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage}  />
    <Realestateheade selectedLanguage={props.selectedLanguage} />
    <RealStateBody selectedLanguage={props.selectedLanguage} />
    <Keyproject selectedLanguage={props.selectedLanguage} />
    <ExploreRealestate setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} />
    <Footer setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} />
    </div>
  </>
  )
}

export default RealEstate