import React from 'react'
import './journey.css'

const Journey = ({selectedLanguage}) => {
  return (
    <>
       {/* // <section className='aboutjourney-section'>
    //     <Row >
    //         <Col className='col-md-12'>
    //         <div className="scrollHalf">
  
    //             <div className="scrollHalf__grupo" data-scroll>
    //                 <div className="scrollHalf__grupo__contenido">
    //                 <h1>Titulo 1</h1>
                    
    //                 Betelgeuse
    //                 Map of the constellation Orion
    //                 Red circle.svg
    //                 Betelgeuse (circled) in the constellation Orion
    //                 Observation data
    //                 Epoch J2000.0      Equinox J2000.0
    //                 Constellation	Orion
    //                 Pronunciation	/ˈbiːtəldʒuːz, ˈbɛtəl-, -dʒuːs/[1][2]
    //                 Right ascension	05h 55m 10.30536s[3]
    //                 Declination	+07° 24′ 25.4304″[3]
    //                 Characteristics
    //                 Evolutionary stage	Red supergiant
    //                 Spectral type	M1–M2 Ia–ab[4]
    //                 Apparent magnitude (V)	0.50[5] (0.0 - 1.3[6])
    //                 Apparent magnitude (J)	−3.00[7]
    //                 Apparent magnitude (K)	−4.05[7]
    //                 U−B color index	+2.06[5]
    //                 B−V color index	+1.85[5]
    //                 Variable type	SRc[6]
    //                 Astrometry
    //                 Radial velocity (Rv)	+21.91[8] km/s
    //                 Proper motion (μ)	RA: 26.42 ± 0.25[9] mas/yr
    //                 Dec.: 9.60 ± 0.12[9] mas/yr
    //                 Parallax (π)	4.51 ± 0.80[9] mas
    //                 Distance	222+48
    //                 −34[9] pc
    //                 Absolute magnitude (MV)	−5.85[10]
    //                 Details
    //                 Mass	11.6+5.0
    //                 −3.9[11] M☉
    //                 Radius	887 ± 203[12], 955±217[11] R☉
    //                 Luminosity	90000 – 150000[13] L☉
    //                 Surface gravity (log g)	−0.5[14] cgs
    //                 Temperature	3590[11] K
    //                 Metallicity [Fe/H]	+0.05[15] dex
    //                 Rotational velocity (v sin i)	5[16] km/s
    //                 Age	8.0 – 8.5[12] Myr
    //                 Other designations
    //                 Betelgeuse, α Ori, 58 Ori, HR 2061, BD+7°1055, HD 39801, FK5 224, HIP 27989, SAO 113271, GC 7451, CCDM J05552+0724AP, AAVSO 0549+07
    //                 Database references
    //                 SIMBAD	data
    //                 Coordinates: Sky map 05h 55m 10.3053s, +07° 24′ 25.426″

    //                     </div>
    //                     <div className="scrollHalf__grupo__imagen">
    //                     <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106155/OrionTree_Fairbairn_1071.jpg" />
    //                     </div>
                    
    //                 </div>
                    
    //                 <div className="scrollHalf__grupo kk" data-scroll>

    //                     <div className="scrollHalf__grupo__contenido">
    //                     <h1>Titulo 2</h1>
    //                 This orange blob shows the nearby star Betelgeuse, as seen by the Atacama Large Millimeter/submillimeter Array (ALMA). This is the first time that ALMA has ever observed the surface of a star and this first attempt has resulted in the highest-resolution image of Betelgeuse available.
    //                 Betelgeuse, also designated Alpha Orionis (α Orionis, abbreviated Alpha Ori, α Ori), is on average the ninth-brightest star in the night sky and second-brightest in the constellation of Orion. It is distinctly reddish, and is a semiregular variable star whose apparent magnitude varies between 0.0 and 1.3, the widest range of any first-magnitude star. Betelgeuse is one of three stars that make up the Winter Triangle asterism, and it marks the center of the Winter Hexagon. It would be the brightest star in the night sky if the human eye could view all wavelengths of radiation. fghfghfh fg hgfd
    //                     </div>
    //                         <div className="scrollHalf__grupo__imagen">
    //                     <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106155/1920px-Betelgeuse_captured_by_ALMA.jpg" />
             
    //                     </div>
    //                 </div>
                    
    //                 <div className="scrollHalf__grupo" data-scroll>

    //                     <div className="scrollHalf__grupo__contenido">
    //                     <h1>Titulo 3</h1>
    //                 Classified as a red supergiant of spectral type M1-2, the star is one of the largest stars visible to the naked eye. If Betelgeuse were at the center of the Solar System, its surface would extend past the asteroid belt, completely engulfing the orbits of Mercury, Venus, Earth, Mars, and possibly Jupiter. However, there are several other red supergiants in the Milky Way that could be larger, such as Mu Cephei, VV Cephei A, and VY Canis Majoris. Calculations of its mass range from slightly under ten to a little over twenty times that of the Sun. It is calculated to be 640 light-years away, yielding an absolute magnitude of about −6. Less than 10 million years old, Betelgeuse has evolved rapidly because of its high mass. Having been ejected from its birthplace in the Orion OB1 Association—which includes the stars in Orion's Belt—this runaway star has been observed moving through the interstellar medium at a speed of 30 km/s, creating a bow shock over four light-years wide. Currently in a late stage of stellar evolution, the supergiant is expected to explode as a supernova within the next million years.

    //                 In 1920, Betelgeuse became the first extrasolar star to have the angular size of its photosphere measured. Subsequent studies have reported an angular diameter (apparent size) ranging from 0.042 to 0.056 arcseconds, with the differences ascribed to the non-sphericity, limb darkening, pulsations, and varying appearance at different wavelengths. It is also surrounded by a complex, asymmetric envelope roughly 250 times the size of the star, caused by mass loss from the star itself. The angular diameter of Betelgeuse is only exceeded by R Doradus (and the Sun).
    //                     </div>
    //                         <div className="scrollHalf__grupo__imagen">
    //                     <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106155/potw1726b.jpg" />
    //                     </div>
    //                 </div> 
                    
    //                 </div>
    //         </Col>
    //     </Row>
    // </section> */}
  
    <div className={(selectedLanguage === "en") ? 'desktop journey' : 'desktop journey ar'} >
        <section className='section'>
			<div className="left">
            <div className="inner text-div">

               {(selectedLanguage === "en") ? (<> 
                     <h2 className="aboutjourney-heading">Journey <br />
                        <span className="aboutjourney-sub-heading">So Far</span>
                    </h2>
                    <p className="aboutjourney-para">As a family business, MXB Investment has a long history that began in the early years of modern Saudi Arabia. Two brothers, the late Sheikh Saad Almousa and the late Sheikh Abdulaziz Almousa, were visionary leaders and businessmen who recognized the potential of Saudi Arabia's real estate market. Later, late Sheikh Mousa Saad Almousa expanded the family business by establishing MXB in 1984 and successfully leading the company in the development of real estate in the Kingdom.</p>
                    <p className="aboutjourney-para">The philosophy of MXB is influenced by the late Sheikh Mohammed Ibrahim Alsubeaei, founder of MASIC, He was a prominent businessman who played a pivotal role in the development of modern Saudi Arabia. His accomplishments are major source of inspiration for generations to come.</p>
                    <p className="aboutjourney-para">Carrying the family legacy forward, Mr. Mishal Almousa currently holds the office of Managing Director at MXB. He is dedicated to continuing the work of their forefathers and expanding on their achievements, with an emphasis on sustainability and innovation.</p>
                    <p className="aboutjourney-para">Being at forefront of major real estate projects in the Kingdom of Saudi Arabia, MXB embraces change and growth while remaining rooted in its values and traditions as it looks to the future.</p>
                </>) : (<> 
                    <h2 className="aboutjourney-heading">
                        <span className="aboutjourney-sub-heading">مسيرة الشركة</span>
                    </h2>
                    <p className="aboutjourney-para">تحظى شركة مكسب للاستثمار العائلية بتاريخ طويل انطلق مع السنوات الأولى لقيام المملكة العربية السعودية الحديثة، وكان الأخوان، الشيخ الراحل سعد الموسى والشيخ الراحل عبد العزيز الموسى - رحمهما الله -، من القادة ورجال الأعمال ذوي الرؤى الثاقبة اللذان درسا إمكانيات سوق العقارات في المملكة العربية السعودية، وعليه قام الشيخ الراحل موسى سعد الموسى بتوسيع الأعمال العائلية وتأسيس شركة مكسب عام 1984 وقيادة الشركة بنجاح في تطوير العقارات في المملكة العربية السعودية. </p>
                    <p className="aboutjourney-para">تأثرت فلسفة شركة مكسب للاستثمار أيضا بالشيخ الراحل محمد إبراهيم السبيعي - رحمه الله-، مؤسس شركة ماسك ورجل الأعمال المتميز الذي لعب دوراً محورياً في تطوير المملكة العربية السعودية الحديثة، حيث كانت ولا تزال انجازاته منارةً تستنير بها الأجيال القادمة.</p>
                    <p className="aboutjourney-para">يحمل السيد مشعل الموسى إرث العائلة الآن ويشغل منصب المدير التنفيذي في شركة مكسب للاستثمار، ويلتزم السيد مشعل بمتابعة مسيرة أجداده بخطى ثابتة وتوسيع دائرة إنجازاتهم، ويركز على الاستدامة والإبداع في كل خطوة يخطوها.</p>
                    <p className="aboutjourney-para">صعودنا نحو القمة في مشروعات العقار في المملكة العربية السعودية يعني أن نعتنق عقيدة التغيير والنمو وأن نحافظ على جذورنا المتأصلة وقيمنا وتقاليدنا في تطلعنا للمستقبل.</p>
                </>)}

            </div>
				
			</div>
			<div className="right">
                <div className="inner sticky">
					<div className="bg bg1"></div>
				</div>		
			</div>
	    </section> 


        <section className='section'>
			<div className="left">
            <div className="inner text-div">

            {(selectedLanguage === "en") ? (
                <>
                    <h2 className="aboutjourney-heading">Corporate<br />
                        <span className="aboutjourney-sub-heading">Governance</span>
                    </h2>

                    <p className="aboutjourney-para">We lead the way in corporate governance based on the principles of fairness, transparency, accountability, and responsibility as a family-owned business. Corporate governance is a key differentiator for us and a pillar in establishing our reputation as a competent, ethical, and responsive investment company. Corporate governance is an essential aspect of our business strategy, and we are committed to upholding the highest standards in this area. By doing so, we believe that we can continue to build trust and credibility with our stakeholders and achieve long-term success as a family-owned investment company. The committees included in our governance structure are:</p>
                      
                    <ul className="text-start Governance-list pb-5">
                        <li>Executive Committee</li>
                        <li>Nomination and Remuneration Committee</li>
                        <li>Audit &amp; Risk Committee</li>
                    </ul>
                </>
            ) : (
                <>
                
                    <h2 className="aboutjourney-heading">
                        <span className="aboutjourney-sub-heading">حوكمة الشركة</span>
                    </h2>

                    <p className="aboutjourney-para">نقود مسيرتنا في حوكمة شركتنا العائلية على أسس العدالة والشفافية والمساءلة والمسؤولية، وننظر إلى حوكمة شركتنا كعامل التميّز وركن الأساس لبناء سمعتنا والتعريف بنا كشركة استثمار تمتاز باستثماراتها الأخلاقية وتتسم بالكفاءة والفاعلية.
تعتبر حوكمة شركتنا جزءاً لا يتجزأ من استراتيجية أعمالنا، ونلتزم بالمحافظة على أعلى المعايير في وضع استراتيجيتنا، ويظهر هذا الالتزام إيماننا الراسخ بقدرتنا على بناء الثقة والاعتمادية العالية لأصحاب المصالح دون استثناء وتحقيق النجاح على المدى الطويل لشركة الاستثمار العائلية.</p>
                    
                    <p className="aboutjourney-para">تعمل اللجان التالية على وضع وتحديد حوكمة شركتنا:</p>
                    <ul className="text-start Governance-list pb-5">
                        <li>اللجنة التنفيذية</li>
                        <li>لجنة الترشيحات والمكافآت</li>
                        <li>لجنة التدقيق والمخاطر</li>
                    </ul>
                </>
            )} 

            </div>
				
			</div>
			<div className="right">
                <div className="inner sticky">
					<div className="bg bg2"></div>
				</div>		
			</div>
	    </section> 

        <section className='section'>
			<div className="left">
            <div className="inner text-div">

            {(selectedLanguage === "en") ? (
                <>
                    <h2 className="aboutjourney-heading">Succession<br />
                        <span className="aboutjourney-sub-heading">Plan</span>
                    </h2>
                        
                    <p className="aboutjourney-para third-para">MXB's leadership recognizes the importance of developing successors to ensure a smooth transition and uninterrupted operations. Through this strategic approach, MXB is able to maintain a high level of competence within the organization, ultimately leading to a successful and thriving business. By investing in succession planning, MXB shall nurture it’s people and ensure valuable career progression. This not only benefits the individuals themselves but also contributes to the overall success and sustainability of the business.</p>                
                </>
            ) : (
                <>
                    <h2 className="aboutjourney-heading">
                        <span className="aboutjourney-sub-heading">خطة الاستمرارية</span>
                    </h2>
                        
                    <p className="aboutjourney-para third-para">تعلم إدارة شركة مكسب للاستثمار أهمية إعداد وتطوير الخلفاء لضمان الإنتقال السلس للعمليات إلى الأجيال القادمة وعدم إنقطاعها، وتضمن شركة مكسب للاستثمار في تطبيقها لخطط التعاقب المحافظة على أعلى مستويات الكفاءة داخل المؤسسة، مما يعود بالمحصلة على أعمالنا ويشكل عاملاً جوهرياً في ازدهارها. نستثمر في خطة الاستمرارية لنمكن شركتنا من دعم وتطوير مسيرة أعمالنا، ونعلم يقيناً أن الفائدة لا تعود فقط على الأفراد، بل تساهم أيضاً بنجاح الشركة واستدامة أعمالها.</p>
                
                </>
            )}
            </div>
				
			</div>
			<div className="right">
                <div className="inner sticky">
					<div className="bg bg3"></div>
				</div>		
			</div>
	    </section> 
        </div>

        <div className={(selectedLanguage === "en") ? 'mobile journey' : 'mobile journey ar'}>
            <section className='bg bg1'>
            {(selectedLanguage === "en") ? (
                <>
                    <div className='heading-bg'>
                        <h2 className="aboutjourney-heading">Journey <br />
                            <span className="aboutjourney-sub-heading">So Far</span>
                        </h2>
                    </div>
                    <p className="aboutjourney-para">As a family business, MXB Investment has a long history that began in the early years of modern Saudi Arabia. Two brothers, the late Sheikh Saad Almousa and the late Sheikh Abdulaziz Almousa, were visionary leaders and businessmen who recognized the potential of Saudi Arabia's real estate market. Later, late Sheikh Mousa Saad Almousa expanded the family business by establishing MXB in 1984 and successfully leading the company in the development of real estate in the Kingdom.</p>
                    <p className="aboutjourney-para">The philosophy of MXB is influenced by the late Sheikh Mohammed Ibrahim Alsubeaei, founder of MASIC, He was a prominent businessman who played a pivotal role in the development of modern Saudi Arabia. His accomplishments are major source of inspiration for generations to come.</p>
                    <p className="aboutjourney-para">Carrying the family legacy forward, Mr. Mishal Almousa currently holds the office of Managing Director at MXB. He is dedicated to continuing the work of their forefathers and expanding on their achievements, with an emphasis on sustainability and innovation.<br />Being at forefront of major real estate projects in the Kingdom of Saudi Arabia, MXB embraces change and growth while remaining rooted in its values and traditions as it looks to the future.</p>
                </>
            ) : (
                <>
                    <div className='heading-bg'>
                        <h2 className="aboutjourney-heading">
                            <span className="aboutjourney-sub-heading">مسيرة الشركة</span>
                        </h2>
                    </div>
                    <p className="aboutjourney-para">تحظى شركة مكسب للاستثمار العائلية بتاريخ طويل انطلق مع السنوات الأولى لقيام المملكة العربية السعودية الحديثة، وكان الأخوان، الشيخ الراحل سعد الموسى والشيخ الراحل عبد العزيز الموسى - رحمهما الله -، من القادة ورجال الأعمال ذوي الرؤى الثاقبة اللذان درسا إمكانيات سوق العقارات في المملكة العربية السعودية، وعليه قام الشيخ الراحل موسى سعد الموسى بتوسيع الأعمال العائلية وتأسيس شركة مكسب عام 1984 وقيادة الشركة بنجاح في تطوير العقارات في المملكة العربية السعودية. </p>
                    <p className="aboutjourney-para">تأثرت فلسفة شركة مكسب للاستثمار أيضا بالشيخ الراحل محمد إبراهيم السبيعي - رحمه الله-، مؤسس شركة ماسك ورجل الأعمال المتميز الذي لعب دوراً محورياً في تطوير المملكة العربية السعودية الحديثة، حيث كانت ولا تزال انجازاته منارةً تستنير بها الأجيال القادمة.</p>
                    <p className="aboutjourney-para">يحمل السيد مشعل الموسى إرث العائلة الآن ويشغل منصب المدير التنفيذي في شركة مكسب للاستثمار، ويلتزم السيد مشعل بمتابعة مسيرة أجداده بخطى ثابتة وتوسيع دائرة إنجازاتهم، ويركز على الاستدامة والإبداع في كل خطوة يخطوها.</p>
                    <p className="aboutjourney-para">صعودنا نحو القمة في مشروعات العقار في المملكة العربية السعودية يعني أن نعتنق عقيدة التغيير والنمو وأن نحافظ على جذورنا المتأصلة وقيمنا وتقاليدنا في تطلعنا للمستقبل.</p>
                </>
            )}
        
            </section>

            <section className='bg bg2 mt-3'>
            {(selectedLanguage === "en") ? (
                <>
                    <div className='heading-bg'>
                        <h2 className="aboutjourney-heading">Corporate<br />
                            <span className="aboutjourney-sub-heading">Governance</span>
                        </h2>
                    </div>
                    <p className="aboutjourney-para">We lead the way in corporate governance based on the principles of fairness, transparency, accountability, and responsibility as a family-owned business. Corporate governance is a key differentiator for us and a pillar in establishing our reputation as a competent, ethical, and responsive investment company. Corporate governance is an essential aspect of our business strategy, and we are committed to upholding the highest standards in this area. By doing so, we believe that we can continue to build trust and credibility with our stakeholders and achieve long-term success as a family-owned investment company. The committees included in our governance structure are:</p>
                    
                    <ul className="text-start Governance-list pb-5">
                        <li>Executive Committee</li>
                        <li>Nomination and Remuneration Committee</li>
                        <li>Audit &amp; Risk Committee</li>
                    </ul>    
                </>
            ) : (
                <>
                    <div className='heading-bg'>
                        <h2 className="aboutjourney-heading">
                            <span className="aboutjourney-sub-heading">حوكمة الشركة</span>
                        </h2>
                    </div>
                    <p className="aboutjourney-para">نقود مسيرتنا في حوكمة شركتنا العائلية على أسس العدالة والشفافية والمساءلة والمسؤولية، وننظر إلى حوكمة شركتنا كعامل التميّز وركن الأساس لبناء سمعتنا والتعريف بنا كشركة استثمار تمتاز باستثماراتها الأخلاقية وتتسم بالكفاءة والفاعلية.
تعتبر حوكمة شركتنا جزءاً لا يتجزأ من استراتيجية أعمالنا، ونلتزم بالمحافظة على أعلى المعايير في وضع استراتيجيتنا، ويظهر هذا الالتزام إيماننا الراسخ بقدرتنا على بناء الثقة والاعتمادية العالية لأصحاب المصالح دون استثناء وتحقيق النجاح على المدى الطويل لشركة الاستثمار العائلية.</p>
                    
                    <p className="aboutjourney-para">تعمل اللجان التالية على وضع وتحديد حوكمة شركتنا:</p>
                    <ul className={(selectedLanguage === "en") ? "text-start Governance-list pb-5" : "text-end Governance-list pb-5"} >
                        <li>اللجنة التنفيذية</li>
                        <li>لجنة الترشيحات والمكافآت</li>
                        <li>لجنة التدقيق والمخاطر</li>
                    </ul>   
                  
                </>
            )}
                
            </section>

            <section className='bg bg3 mt-3'>
            {(selectedLanguage === "en") ? (
                <>
                        <div className='heading-bg'>
                        <h2 className="aboutjourney-heading">Succession<br />
                        <span className="aboutjourney-sub-heading">Plan</span>
                        </h2>
                    </div>
                    <p className="aboutjourney-para third-para">MXB's leadership recognizes the importance of developing successors to ensure a smooth transition and uninterrupted operations. Through this strategic approach, MXB is able to maintain a high level of competence within the organization, ultimately leading to a successful and thriving business. By investing in succession planning, MXB can nurture and advance the careers of its employees. This not only benefits the individuals themselves but also contributes to the overall success and sustainability of the business.</p>
                </>
              ) : (
                <>
                    <div className='heading-bg'>
                        <h2 className="aboutjourney-heading">
                            <span className="aboutjourney-sub-heading">خطة الاستمرارية</span>
                        </h2>
                    </div>
                    <p className="aboutjourney-para third-para">تعلم إدارة شركة مكسب للاستثمار أهمية إعداد وتطوير الخلفاء لضمان الإنتقال السلس للعمليات إلى الأجيال القادمة وعدم إنقطاعها، وتضمن شركة مكسب للاستثمار في تطبيقها لخطط التعاقب المحافظة على أعلى مستويات الكفاءة داخل المؤسسة، مما يعود بالمحصلة على أعمالنا ويشكل عاملاً جوهرياً في ازدهارها. نستثمر في خطة الاستمرارية لنمكن شركتنا من دعم وتطوير مسيرة أعمالنا، ونعلم يقيناً أن الفائدة لا تعود فقط على الأفراد، بل تساهم أيضاً بنجاح الشركة واستدامة أعمالها.</p>
                
                </>
            )}  
            
            </section>

        </div>


    </>
  )
}

export default Journey