import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import leftbottom from '../images/am-bottom.webp'
import righttop from '../images/am-top.webp'

import leftbottommb from '../images/am-bottom-mb.webp'
import righttopmb from '../images/am-top-mb.webp'

import './aboutmanagingdirector.css'

// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

// AOS.init({
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 400, // values from 0 to 3000, with step 50ms
//     easing: 'ease', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
// });

const AboutManagingDirector = ({selectedLanguage}) => {
    return (
        <>
            <section  className={selectedLanguage === "en" ? 'aboutmanaging-section' : 'aboutmanaging-section ar' } >
                    <div className='righttop '>
                      <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine"
                        data-aos-anchor=".aboutmanaging-section"  data-aos-anchor-placement="top-bottom" >
                        <img className='desktop' src={righttop} alt='' />
                        <img className='mobile' src={righttopmb} alt='' />
                      </div>
                    </div>
                    
                    <div className='leftbottom '>
                      <div data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine"
                        data-aos-anchor=".aboutmanaging-section" data-aos-anchor-placement="top-bottom" >
                        <img className='desktop' src={leftbottom} alt='' />
                        <img className='mobile' src={leftbottommb} alt='' />     
                      </div>               
                    </div>
            
                <Container>
                    <Row>
                        <div className='img-text text-center'>
                            <Container>

                            {selectedLanguage === "en" ? (
                                <>
                                <Row>
                                    <Col className='col-md-9'>
                                        <h2 className='img-heading'>Mr. Mishal AlMousa</h2>
                                        <h4 className='img-sub-heading'>Managing Partner at MXB</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-1 desktop'></Col>
                                    <Col className='col-md-11'>
                                        <p className='img-para'>Mr. Mishal AlMousa, the Managing Partner of MXB Investment, brings a wealth of experience in the management of investments and real estate. He completed his Executive MBA from the London Business School, and currently serves as a Board Member in multiple organizations across a wide range of industries leveraging his keen insights and vast expertise in multiple industries.<br/><br/>

                                            Mr. Mishal began his career in corporate banking. He worked for SAB Bank and Albilad Bank. He has also managed to help grow the family business' investment portfolio by sourcing deals, engineering investments and maximizing the value creation. By leveraging his vast professional network, he has been able to identify promising opportunities.<br/><br/>
                                            Carrying the legacy of his family, Mr. Mishal aims to be a leading force in significant real estate initiatives within the Kingdom, adeptly balancing his commitment to evolution and expansion with a steadfast adherence to core values and traditions, ensuring a progressive outlook towards the future.
                                        </p>
                                    </Col>
                                </Row>                                
                                </>
                            ) : (
                                <>
                                <Row>
                                    <Col className='col-md-9'>
                                        <h2 className='img-heading'>السيد مشعل الموسى</h2>
                                        {/* <h4 className='img-sub-heading'>العضو المنتدب في مكسب</h4> */}
                                        <h4 className='img-sub-heading'>المدير التنفيذي في شركة مكسب للاستثمار</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-md-1 desktop'></Col>
                                    <Col className='col-md-11'>
                                        {/* <p className='img-para'>يتمتع السيد مشعل الموسى، المدير العام لشركة مكسب للاستثمار، بثروة من الخبرة في إدارة الاستثمارات والمشروعات العقارية. فقد حصل على ماجستير إدارة الأعمال التنفيذي "MBA" من كلية لندن للأعمال، ويشّغل حاليًا منصب عضو مجلس إدارة في العديد من المنظمات مستفيدًا من أفكاره العميقة وخبرته الواسعة في العديد من المجالات. <br/><br/>
                                        بدأ السيد مشعل حياته المهنية في الخدمات المصرفية للشركات. وعمل في بنك ساب وبنك البلاد. كما نجح في المساعدة في تنمية المحفظة الاستثمارية لشركات العائلة من خلال إيجاد الصفقات وتحليل الاستثمارات واتخاذ القرارات المهمة. ونظرًا لما يملكه من شبكة معارف ومدارك ممتدة، تمكن من تحديد الفرص الاستثمارية الواعدة.<br/><br/>
                                        ويسعى السيد مشعل، الذي يحمل إرث عائلته، إلى أن يكون قوة رائدة في المبادرات العقارية الهامة داخل المملكة، حيث يوازن بمهارة التزامه بالتطور والتوسع مع الالتزام الثابت بالقيم الأساسية والتقاليد العريقة، مما يضمن نظرة تقدمية نحو المستقبل.
                                        </p> */}

                                        <p className='img-para'>يتمتع السيد مشعل الموسى - المدير التنفيذي لشركة مكسب للاستثمار-  بخبرة كبيرة في إدارة الاستثمارات والمشاريع العقارية، حيث يحمل شهادة ماجستير إدارة الأعمال التنفيذي EMBA من كلية لندن للأعمال، ويشّغل حاليًا منصب عضو مجلس إدارة في العديد من الكيانات الربحية وغير الربحية مستفيدا من خبرته الواسعة في عدة قطاعات.  <br/><br/>                                       
                                            بدأ السيد مشعل حياته المهنية في قطاع الخدمات المصرفية للشركات، وعمل في عدة شركات منها مصرفية الشركات لدى بنكي ساب والبلاد، كما تمكن من تنمية المحفظة الاستثمارية لشركات العائلة بالتزامن عن طريق تحديد الصفقات وتحليل الاستثمارات واتخاذ القرارات المهمة، وتمكن من تحديد الفرص الاستثمارية الواعدة بالاستفادة من شبكة معارفه الواسعةوخبراته الممتدة.<br/><br/>
                                            ويسعى السيد مشعل لمتابعة إرث عائلته بأن يحافظ على شركة مكسب للاستثمار في طليعة المبادرات الاستثمارية الهامة داخل المملكة، حيث يوازن ما بين التزامه بالتطورير والتوسع مع الالتزام الثابت بالقيم الأساسية والتقاليد العريقة، مما يضمن تحقيق رؤيته المستقبلية.
                                        </p>
                                    </Col>
                                </Row>                                
                                </>
                            ) }

                            </Container>
                        </div>
                    </Row>
                </Container>

            </section>
        </>
    )
}

export default AboutManagingDirector