// import React, { useEffect } from 'react'
import './career.css'
import { NavLink } from 'react-router-dom';
import { Row, Col, Container} from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import img1 from '../images/Homepage-CareerOppurtunities.webp'
import imgmb from '../images/Homepage-CareerOppurtunities-mb.webp'



const Career = ({selectedLanguage,setBlank}) => {

    // const navigate = useNavigate();
    // const history = createBrowserHistory();

    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
            setBlank(false);            
        }, 900);
    }

    // useEffect(() => {

        // const scrollToTop = (page) => {
            
        //     // window.scrollTo(0, 0);
        //     // setTimeout(() => {
        //         navigate(page);                 
        //     // }, 100);
        // }
        

    //   }, []);



    return (
        <>
            <section className={(selectedLanguage === "en") ? "career-section desktop" : "career-section desktop ar"}>
                <Container>
                    <Row>
                        <Col className='mt-5 col-md-12 mx-auto pt-4'>
                            <img src={img1} alt='' className='career-image w-100 ' />
                        </Col>
                    </Row>
                   
                </Container>
                <Container className='img-text'>
                <Row>
                        <Col className='col-md-6'>
                            <h2 className='img-text-heading'>
                            {(selectedLanguage === "en") ? (<>Career Opportunities</>) : (<>الفرص الوظيفية</>)}</h2>
                            <p className='img-text-para'>{(selectedLanguage === "en") ? (<>Be a part of purpose-driven ethical wealth creation </>) : (<>شاركنا في بناء الثروات الفعّال </>)}</p>
                           
                            <NavLink to="/careers" onClick={scrollToTop}>
                                <button className='img-text-button'>{(selectedLanguage === "en") ? (<>Explore Now </>) : (<>اكتشف الفرص الوظيفية</>)}</button>
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={(selectedLanguage === "en") ? "career-section mobile" : "career-section mobile ar"}  >
            <Container fluid className='p-0 m-0'>
                     <Row className='g-0'>
                        <Col className='col-md-12'>
                            <div  className='img-text'>
                                <h2 className='img-text-heading'>{(selectedLanguage === "en") ? (<>Career Opportunities</>) : (<>الفرص الوظيفية</>)}</h2>
                                <p className='img-text-para'>{(selectedLanguage === "en") ? (<>Be a part of purpose-driven ethical wealth creation </>) : (<>شاركنا في بناء الثروات الفعّال </>)}</p>
                           
                                <img src={imgmb} alt='' className='career-image w-100 ' />
                               
                                <NavLink to="/careers" onClick={scrollToTop}>
                                     <button className='img-text-button mt-4'>{(selectedLanguage === "en") ? (<>Explore Now </>) : (<>اكتشف الفرص الوظيفية</>)}</button>
                                 </NavLink>  
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
                   
            </section>

        </>
    )
}

export default Career