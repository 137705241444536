//import logo from './logo.svg';
import './App.css';
import  {useState} from 'react'
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from './pages/Homepage';
// import Menu from './component/Menu';
import { Route, Routes, UNSAFE_useScrollRestoration  } from 'react-router-dom';
import Career from './pages/Career';
import Investment from './pages/Investment';
import Contact from './pages/Contact';
import RealEstate from './pages/RealEstate';
import About from './pages/About';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
//import Mxbinvestment from './component/Mxbinvestment';


import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init({
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 350, // values from 0 to 3000, with step 50ms
    easing: 'ease-in', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-center', // defines which position of the element regarding to window should trigger the animation
});


function App() {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [blank, setBlank] = useState(false);

  // const scrollRestoration = UNSAFE_useScrollRestoration();

  return (
    <>
    
    <Helmet>
      <title>Preferred Square</title>
      <meta name='description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div className="App">
      {/* <Menu/> */}
      {(blank) ? <div className='blank'> </div> : ""}
      <Routes scrollRestoration={UNSAFE_useScrollRestoration}>
        <Route path='/' element={<Homepage setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
        <Route path='home' element={<Homepage setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
        <Route path='about' element={<About setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
        <Route path='careers' element={<Career setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
        <Route path='investments' element={<Investment setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
        <Route path='realEstate' element={<RealEstate setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}  />}/>
        <Route path='Contact' element={<Contact setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
        <Route path='terms-and-conditions' element={<Terms setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
        <Route path='privacypolicy' element={<PrivacyPolicy setBlank={setBlank} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}/>
      </Routes>
    </div>
    </>

  );
}

export default App;
