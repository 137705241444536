import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './ourvaluesystem.css'
// import lefttop from '../images/lefttop-ov.webp'
// import rightbottom from '../images/rightbottom-ov.webp'
import lefttop from '../images/our-value-top.webp'
import rightbottom from '../images/our-value-bottom.webp'
import lefttopmb from '../images/our-value-top-mb.webp'
import rightbottommb from '../images/our-value-bottom-mb.webp'


import img1 from '../images/valueimg1.webp'
import img2 from '../images/valueimg2.webp'
import img3 from '../images/valueimg3.webp'


// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

// AOS.init({
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 450, // values from 0 to 3000, with step 50ms
//     easing: 'ease-in', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-center', // defines which position of the element regarding to window should trigger the animation
// });



  const valuedata = [
    {
      "id": 1,
      "image": img1,
      "imgheading": "Be a trusted & <br /> active investor",
      "imgcontent": "We actively monitor and manage our investments, seize market opportunities and adapt to the evolving market dynamics to deliver optimal results while also maintaining an open and transparent communication with all our stakeholders.",
      animation: "fade-down-right"
    },
    {
      "id": 2,
      "image": img2,
      "imgheading": "Drive sustainable <br /> wealth creation",
      "imgcontent": "We believe in prioritizing investments that not only create sustainable returns but are also aligned with global environmental, societal and governance practices.",
       animation: "fade-down"
    },
    {
      "id": 3,
      "image": img3,
      "imgheading": "Follow a high-conviction approach to investment",
      "imgcontent": "We are able to consistently create long-lasting value in a dynamic and complex business environment by adopting a research and analysis driven investment approach.",
      animation: "fade-down-left"
    }
  ]

                   
const valuedataar = [
    {
      "id": 1,
      "image": img1,
      "imgheading": "الاستثمار الموثوق والفعّال",
      "imgcontent": "نراقب استثماراتنا ونديرها بفعالية، ونغتنم فرص السوق ونتكيف مع تغيراته لتحقيق أفضل النتائج ونحافظ في ذات الوقت على قنوات الاتصال مفتوحة بكل شفافية مع جميع الأطراف المعنية.",
      animation: "fade-down-left"
    },
    {
      "id": 2,
      "image": img2,
      "imgheading": "بناء الثروة المستدامة",
      "imgcontent": "نحدد أولوية الاستثمارات حسب العوائد المستدامة وتماشيها مع الممارسات البيئية والمجتمعية والحوكمة العالمية.",
       animation: "fade-down"
    },
    {
      "id": 3,
      "image": img3,
      "imgheading": "التركيز على الاستثمارات المضمونة طويلة الأج",
      "imgcontent": "نتميز بقدرتنا على خلق قيمة طويلة الأجل في بيئات الأعمال النشطة والمعقدة من خلال اعتماد نهج استثماري قائم على البحث والتحليل.",
      animation: "fade-down-right"
    }
  ]

const Ourvaluesystem = ({selectedLanguage,setBlank}) => {
    return (
        <>
            <section className={(selectedLanguage === "en") ? "value-section" : "value-section ar"} >
              <div className='lefttop'>
                  <div data-aos="fade-right" data-aos-offset="25" data-aos-easing="ease-in-sine" 
                  data-aos-anchor=".value-section"
                  data-aos-anchor-placement="top-center">
                    <img className='desktop' src={lefttop} alt='' />
                    <img className='mobile' src={lefttopmb} alt='' />
                   </div>
              </div>
              <div className='rightbottom'>
                 <div data-aos="fade-up" data-aos-offset="25" data-aos-easing="ease-in-sine"
                  data-aos-anchor=".value-section"
                  data-aos-anchor-placement="top-center" >
                    <img className='desktop' src={rightbottom} alt='' />
                    <img className='mobile' src={rightbottommb} alt='' />
                </div>
              </div>  
                <div>

                {selectedLanguage === "en" ? (
                    <>
                    <div className='heading-box'>
                        <h4 className='value-heading'>Our Value System</h4>
                        <p className='value-para desktop'>These principles form the bedrock of our operations, pushing us to strive for excellence,<br />embrace continuous improvement, and deliver sustainable results.</p>
                        <p className='value-para mobile'>These principles form the bedrock of our operations, pushing us to strive for excellence, embrace continuous improvement, and deliver sustainable results.</p>
                    </div>
 
                    <Row className='mt-5 pt-3'>
                        {/* <div className='col-md-2'></div> */}
                        <div className='col-md-10 mx-auto'>
                            <Container>
                                <Row>
                                    {
                                        valuedata.map((d) => {
                                            return (
                                                <div key={d.id} className='col-md-4'>
                                                    {/* <div className='our-value-box'> */}
                                                        <div className='text-center bg-box'
                                                         data-aos={d.animation} data-aos-offset="25"
                                                         data-aos-anchor=".value-section"
                                                         data-aos-easing="ease-in-sine" 
                                                        //   data-aos-anchor-placement="top-center" 
                                                         >
                                                            <div className='inner-div'>
                                                                <div className='img-div'>
                                                                <img style={(d.image === img1) ? {paddingTop:"30px"} : {paddingTop:"50px"}} src={d.image} alt='' />
                                                                </div>
                                                                <h4 className='img-heading mt-4' dangerouslySetInnerHTML={{ __html: d.imgheading }}></h4>
                                                                <p className='img-para'>{d.imgcontent}</p>
                                                            </div>
                                                        </div>
                                                    {/* </div> */}
                                                </div>
                                            )
                                        })
                                    }
                                </Row >
                            </Container>
                        </div>
                        {/* <div className='col-md-2'></div> */}
                    </Row>
               
                    </>
                ) : (
                    <>
                    <div className='heading-box'>
                        <h4 className='value-heading'>قيمنا</h4>
                        <p className='value-para desktop'>تمثل مبادئنا حجر الأساس لأعمالنا، وهي ما يدفعنا نحو التميز وتبَني سُبل التطوير المستمر وتقديم نتائج استثنائية بطريقة مستدام</p>
                        <p className='value-para mobile'>تمثل مبادئنا حجر الأساس لأعمالنا، وهي ما يدفعنا نحو التميز وتبَني سُبل التطوير المستمر وتقديم نتائج استثنائية بطريقة مستدام </p>
                    </div>
 
                    <Row className='mt-5 pt-5'>
                        {/* <div className='col-md-2'></div> */}
                        <div className='col-md-10 mx-auto'>
                            <Container>
                                <Row>
                                    {
                                        valuedataar.map((d) => {
                                            return (
                                                <div key={d.id} className='col-md-4' >
                                                    {/* <div className='our-value-box'> */}
                                                        <div className='text-center bg-box'
                                                         data-aos={d.animation} data-aos-offset="25"
                                                         data-aos-anchor=".value-section"
                                                         data-aos-easing="ease-in-sine" 
                                                        //   data-aos-anchor-placement="top-center" 
                                                         >
                                                            <div className='inner-div'>
                                                                <div className='img-div'>
                                                                <img style={(d.image === img1) ? {paddingTop:"30px"} : {paddingTop:"50px"}} src={d.image} alt='' />
                                                                </div>
                                                                <h4 className='img-heading mt-4' dangerouslySetInnerHTML={{ __html: d.imgheading }}></h4>
                                                                <p className='img-para'>{d.imgcontent}</p>
                                                            </div>
                                                        </div>
                                                    {/* </div> */}
                                                </div>
                                            )
                                        })
                                    }
                                </Row >
                            </Container>
                        </div>
                        {/* <div className='col-md-2'></div> */}
                    </Row>
               
                    </>
                ) }


                </div>
            </section >
        </>
    )
}

export default Ourvaluesystem