import React from 'react'
import './careerheader.css'
import { Row, Container,Col } from 'react-bootstrap';


const Careerheader = ({selectedLanguage}) => {
    return (
        <>
            <section>
                <Container fluid className='careerheader-section'>
                    <Row>
                        {/* <h1 className='career-heading'>Career at MXB<br /><span className='fw-bold'>Pursue excellence</span></h1> */}
                        {selectedLanguage === "en" ? (
                        <h1 className='career-heading'>Career at MXB<br /><span className='fw-bold'>Pursue excellence</span></h1>
                        ) : (
                            <h1 className='career-heading'>  التوظيف في مكسب نبحث عن التميز </h1>
                            )}
                    </Row>
                </Container>
                <Container fluid className={selectedLanguage === "en" ? 'careerbotheader-section' : 'careerbotheader-section ar'}>
                    <Row>
                        <Col className='mx-auto positn-relative'>
                            <div className='text-box'>
                            {selectedLanguage === "en" ? <>
                                <h2 className='career-btm-heading'>At MXB, We Grow Together</h2>
                                <p className='career-btm-para'>We are shaping the future of ethical and sustainable wealth creation by relentlessly focusing on innovation and excellence, while also upholding the highest standards of integrity. We believe in creating an environment where our team can thrive and create meaningful impact.</p>
                            </> : <>
                                <h2 className='career-btm-heading'>ننمو معاً في شركة مكسب للاستثمار</h2>
                                <p className='career-btm-para'>نعمل على تحديد ملامح المستقبل لبناء الثروات الأخلاقي والمستدام عن طريق الإبداع والتميز والمحافظة على أعلى معايير النزاهة. نوفر بيئة عمل تمكّن فريقنا من التطور وصنع التأثير الإيجابي.</p>
                            </>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Careerheader