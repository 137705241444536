import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './realestateheader.css'

const Realestateheade = ({selectedLanguage}) => {
    return (
        <>
            <section>
                <Container fluid className='realestateheader-section'>
                    <Row>
                        {selectedLanguage === "en" ? (
                          <h1 className='realestate-heading'>Creating Real Estate <span className='fw-bold'>Legacy</span></h1>
                        ) : (
                          <h1 className='realestate-heading'>بناء إرث عقاري</h1>
                        )}
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Realestateheade