import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './homeheader.css'
// Col,
const Homeheader = ({selectedLanguage}) => {
  return (
    <>
<section className='homeheader-section'>
    <Container>
        <Row >
            {/* <h1 className='header-heading'>Building wealth responsibly <br/><span className='fw-bold'>investing with integrity</span></h1>
           */}
            {selectedLanguage === "en" ? (  
              <h1 className='header-heading'>Creating value responsibly <br/><span className='fw-bold'>investing with integrity</span></h1>          
              ) : (
                <h1 className='header-heading'>مسؤولية في بناء الثروة ونزاهة في الاستثمار</h1>          
                // <h1 className='header-heading'>بناء الثروة بمسؤولية: الاستثمار بنزاهة</h1>          
            )}

        </Row>
    </Container>
</section>
    </>
  )
}

export default Homeheader