import React from 'react'
import { Row, Container } from 'react-bootstrap';
import './ppolicyheader.css'


const Ppolicyheader = ({selectedLanguage}) => {
  return (
    <section>
        <Container fluid className='ppolicy-section'>
            <Row>
            <h1 className='ppolicy-heading'>Privacy policy</h1>
               {/* {selectedLanguage === "en" ? (
                <h1 className='ppolicy-heading'>Privacy policy</h1>
                  ) : (
                    <h1 className='ppolicy-heading'>صفحة سياسة الخصوصية</h1>
                  )} */}
            </Row>
        </Container>
    </section>

  )
}

export default Ppolicyheader
