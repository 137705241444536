import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import Ppolicyheader from '../component/Ppolicyheader'
import Pprivacybody from '../component/Pprivacybody'
import Footer from '../component/Footer';

const PrivacyPolicy = (props) => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
          <meta name='description' content=''/>
          <meta name='keywords' content=''/>
      </Helmet>
      <Menu setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage} />
      <Ppolicyheader selectedLanguage={props.selectedLanguage} /> 
      <Pprivacybody selectedLanguage={props.selectedLanguage} />
      <Footer setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} />
    </>
  )
}

export default PrivacyPolicy
