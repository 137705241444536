import React from 'react'
import './grow_with_mxb.css'
import { Row, Container, Col } from 'react-bootstrap';
const GrowWithMxb = ({selectedLanguage}) => {
    const dataen = [
        {
            "id": 1,
            "heading": "RE Development Director", "content": "Work closely with the CEO to manage MXB’s under-development projects.",
            "button": "Learn More",
            "link": "https://drive.google.com/file/d/1TmS1DOTLAhge_mxN0HTvyl2TEkj2tJuo/view?usp=sharing",
            animation: "fade-down-right"
        },
        // {
        //     "id": 2,
        //     "heading": "Investment & Finance Director",
        //     "content": "Work closely with the CEO to lead the full cycle of the investment process, including deal origination, structuring and execution.",
        //     "button": "Learn More",
        //     "link": "https://drive.google.com/file/d/1SYntQEhr6M8PPCWGrOosxcuv75HG_EC3/view?usp=sharing",
        //     animation: "fade-down"
        // },
        {
            "id": 3,
            "heading": "Operations Manager",
            "content": "Operations Manager oversees the leasing and collection team and ensures the department’s targets and goals are achieved. Proactively collaborate with the team on day to day property management operations. supervise tenants' and clients’ relations and building operations, in support of optimizing assets values.",
            "button": "Learn More",
            "link": "https://drive.google.com/file/d/1hXY26NPshcNG5a6-lVpjTnqCsHS2UuGk/view?usp=drive_link",
            animation: "fade-down-left"
        }
    ]

    const dataar = [
        {
            "id": 1,
            "heading": "مدير تطوير عقاري ", "content": "العمل بشكل مباشر مع الرئيس التنفيذي لإدارة المشاريع التي تقوم مكسب للاستثمار بتطويرها حالياً",
            "button": "اقرأ المزيد حول الدور الوظيفي ",
            "link": "https://drive.google.com/file/d/1TmS1DOTLAhge_mxN0HTvyl2TEkj2tJuo/view?usp=sharing",
            animation: "fade-down-left"
        },
        // {
        //     "id": 2,
        //     "heading": "مدير الاستثمار  والشؤون المالية ",
        //     "content": "العمل بشكل مباشر مع الرئيس التنفيذي على الدورات الاستثمارية الكاملة، متضمنة توليد الصفقات وهيكلتها وتنفيذها",
        //     "button": "اقرأ المزيد حول الدور الوظيفي ",
        //     "link": "https://drive.google.com/file/d/1SYntQEhr6M8PPCWGrOosxcuv75HG_EC3/view?usp=sharing",
        //     animation: "fade-down"
        // },
        {
            "id": 3,
            "heading": "مدير العمليات",
            "content": "يراقب مدير العمليات فريق التأجير والتحصيل ويضمن تحقيق أهداف قسم العمليات، ويتعامل مع الفريق بصورة يومية لإدارة عمليات العقارات، ويشرف على علاقات المستأجرين والعملاء وعمليات البناء بهدف تعظيم قيمة الأصول.",
            "button": "اقرأ المزيد حول الدور الوظيفي ",
            "link": "https://drive.google.com/file/d/1hXY26NPshcNG5a6-lVpjTnqCsHS2UuGk/view?usp=drive_link",
            animation: "fade-down-right"
        }
    ]
    
    var data;
    if(selectedLanguage === "en" )  data = [...dataen]; else data = [...dataar];
    
    return (
        <>
            <section className={(selectedLanguage === "en") ? 'growmxb-section pb-5' : 'growmxb-section ar pb-5' }>
                <div>
                    <h4 className='growmxb-heading'>{(selectedLanguage === "en") ? 'Grow with MXB' : 'التقدم مع مكسب' }</h4>
                    <h5 className='growmxb-sub-heading'>{(selectedLanguage === "en") ? 'Open positions' : 'الوظائف' }</h5>
                    <Row className='mt-5 pt-3'>

                        <Col className='col-md-10 mx-auto'>
                            <Container>
                                <Row style={{justifyContent:"center"}}>
                                    {
                                        data.map((d) => {
                                            return (
                                                <div key={d.id} className='col-md-4'
                                                    data-aos={d.animation} data-aos-offset="00" data-aos-easing="ease-in-sine"
                                                    data-aos-anchor-placement='top-center'
                                                     >
                                                        <div className='bggrey'>
                                                            <div className='inner-div'>
                                                                <h4 className='img-heading mt-4'>{d.heading}</h4>
                                                                <p className='img-para'>{d.content}</p>
                                                                <a style={(d.id === 3) ? {paddingTop:"20px"} : {paddingTop:"40px"}} href={d.link} target='blank'>{d.button} <svg  xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
                                                                    <path d="M11.4762 1L18.1429 7.85714M18.1429 7.85714L11.4762 14.7143M18.1429 7.85714L1 7.85714" stroke="#C8C8C8" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg></a>
                                                            </div>
                                                        </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Row >
                            </Container>
                        </Col>

                    </Row>

                </div>
            </section >
        </>
    )
}

export default GrowWithMxb