import React from 'react'
import { Helmet } from 'react-helmet';
import Menu from '../component/Menu'
import Contactheader from '../component/Contactheader'
import Contactbody from '../component/Contactbody'
import Footer from '../component/Footer';

const Contact = (props) => {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name='description' content=''/>
        <meta name='keywords' content=''/>
      </Helmet>
      <Menu setBlank={props.setBlank} selectedLanguage={props.selectedLanguage} setSelectedLanguage={props.setSelectedLanguage}  />
      <Contactheader selectedLanguage={props.selectedLanguage} />
      <Contactbody selectedLanguage={props.selectedLanguage} />
      <Footer setBlank={props.setBlank}  selectedLanguage={props.selectedLanguage} />
    </>
  )
}

export default Contact